import React, { FC } from 'react';
import { Form, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { media } from '../../../../styleHelpers/breakpoint';
import { NewClusterFormValues } from '../../../../tools/clusterTools';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { Privacy } from '../../../Common/Privacy/Privacy';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { IValue } from '../../../../entities/IPickers';

const FormField = styled.div<{ half?: boolean }>`
    width: 100%;
    padding-bottom: 18px;

    ${(props) => props.half && css`
        ${media.tabletSm`
            width: 50%;
        `}
    `}
`;

export interface ICreatePOAClusterStep {
    isDisabled: boolean;
    parentErrors?: { [key in keyof NewClusterFormValues]?: string };
    onChange: () => void;
}

export const CreatePOAClusterStep: FC<React.PropsWithChildren<ICreatePOAClusterStep>> = ({ isDisabled, parentErrors, onChange }) => {
    const { errors, values, handleBlur, handleChange } = useFormikContext<NewClusterFormValues>();

    const mixedErrors = { ...errors, ...parentErrors };

    return (
        <Form onChange={onChange}>
            <FormField>
                <LabelWithEllipsis required>
                    <FormattedMessage id="poa.wizard.step1.label.title" />
                </LabelWithEllipsis>
                <TextComponent
                    name="name"
                    value={values.name}
                    error={!!mixedErrors.name}
                    errorMsg={mixedErrors.name || ''}
                    onBlur={handleBlur}
                    onChange={(value: string, selectedCurrency: IValue, e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
                    disabled={isDisabled}
                />
            </FormField>
            <FormField>
                <Privacy field="privacy" />
            </FormField>
        </Form>
    );
};
