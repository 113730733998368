import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string-for-all';
import { faGear } from '@fortawesome/pro-solid-svg-icons';

import { IState } from '../../reducers';
import { IContextReducer } from '../../reducers/contextReducer';
import { media } from '../../styleHelpers/breakpoint';
import { ESize } from '../../styleHelpers/sizes';
import { TopBar } from './TopBar/TopBar';
import { SideBar } from './SideBar/SideBar';
import { OverlayContextProvider } from '../NewDesignCommon/Overlay';
import { setExportView } from '../../actions/contextActions';
import { getWopiInfo } from '../../actions/clustersFilesystemActions';
import { wopiFunc } from '../../tools/wopi';
import { useAlert } from '../../tools/hooks';
import { EAlertType } from '../../entities/IAlert';
import * as actionTypes from '../../actions/actionTypes/menuTypes';
import { PanelContainer } from '../Common/Panel/PanelContainer';
import store from '../../store';
import { EMenuItem } from '../../entities/IMenu';
import { ILanguageReducer } from '../../reducers/languageReducer';

type GetWopiInfo = ReturnType<typeof getWopiInfo>;

const ContentWrapper = styled.div<{ withNavigation?: boolean, isRegisterRoute?: boolean }>`
    ${({ isRegisterRoute }) => isRegisterRoute && css`
        padding-left: 0 !important;
    `}
    ${({ withNavigation }) => withNavigation && css`
        padding-top: ${ESize.TopBarHeightMobile}px;
        ${media.desktop`
            padding-top: ${ESize.TopBarHeightDesktop}px;
        `}
    `}
    ${media.desktop`
        :not(.print-layout &) {
            padding-left: ${ESize.Level1Width}px;
        }
    `}
`;

const Content = styled.main<{ isRegisterRoute?: boolean }>`
    ${props => props.isRegisterRoute ? css`
        padding: 0;
    ` : css`
        padding: .65rem;
    `}
    position: relative;
    overflow: hidden;
`;

const WopieWrapper = styled.div`
    .custom-iframe-wopi {
        width: 100vw;
        height: 99vh;
    }
`;

interface IProps {
    isTeamsRoute?: boolean;
    isHomeRoute?: boolean;
    isTeamsConfigPage?: boolean;
    isRegisterRoute?: boolean;
}

const LayoutWrapper: FC<React.PropsWithChildren<{ isPrintLayout?: boolean }>> = ({ isPrintLayout, children }) => (
    <div className={isPrintLayout ? 'print-layout' : ''}>{children}</div>
);

export const Layout: FC<React.PropsWithChildren<IProps>> = ({ children, isTeamsRoute, isHomeRoute, isTeamsConfigPage, isRegisterRoute }) => {
    const { isLoggedIn, isShadowAuth, isInExportView, translations } = useSelector<IState, IContextReducer & ILanguageReducer>(state => ({
        ...state.context,
        ...state.language
    }));
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const { search, pathname } = useLocation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const [showWopi, setShowWopi] = useState<boolean>(false);
    const [wopiInfo, setWopiInfo] = useState<{ accessToken: string, accessTokenTtl: number, ownerId: string, userId: string, wopiSourceUrl: string }>(undefined);

    useEffect(() => {
        switch (pathname) {
            case '/':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.home
                });
                break;
            case '/news':
            case '/news/view':
            case '/news/edit':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.publications,
                    withHeader: true
                });
                break;
            case '/directory':
            case '/network':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.people,
                    withHeader: true
                });
                break;

            case '/companies':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.entities,
                    withHeader: true
                });
                break;

            case '/admin-console':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.administration
                });
                break;

            case '/messaging':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.messaging,
                    withHeader: true
                });
                break;

            case '/notifications':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.notifications,
                    withHeader: true
                });
                break;
            case '/billing':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.billing,
                    withHeader: true
                });
                break;

            case '/settings':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.settings,
                    withHeader: true
                });
                break;

            case '/profile':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.profile
                });
                break;

            case '/invite':
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.invite,
                    withHeader: true
                });
                break;

            case `/orgs/${pathname.split('/')?.[2]}/dashboard`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.explore
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/settings`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.settings,
                    withHeader: true,
                    icon: faGear
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/panel`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.panel
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/rfp/myrequests`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.myrequests,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/entities/datagrid`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.entitiesDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/participation`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.participationDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/entities/graph`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.chart,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/mandate-holders`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.mandateDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/legal-support-dashboard`:
            case `/orgs/${pathname.split('/')?.[2]}/clusters`:
            case `/orgs/${pathname.split('/')?.[2]}/clusters/coi-dashboard`:
            case `/orgs/${pathname.split('/')?.[2]}/clusters/contract-dashboard`:
            case `/orgs/${pathname.split('/')?.[2]}/clusters/gift-dashboard`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.dashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/allFAQ`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.AllFAQ
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/composite-side-letter`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.CompositeSideLetter
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/allRights`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.AllRights
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clausier`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.Clausier,
                    withHeader: false
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/questions`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.AllQNA
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/tracker`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.Tracker,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/project-dashboard`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.projectDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/actions-dashboard`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.actionsDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/directory`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.workspaceUsers,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/poa-holders`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.POA,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/poa-holders`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.poaDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/sponsoring-dashboard`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.sponsoringDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/landlord-dashboard`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.landLordDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/landlord-lease`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.landlordLeaseDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/landlord-lot`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.landlordLotDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/landlord-occupant`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.landlordOccupantDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/trade-association-dashboard`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.tradeAssociationDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/instances`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.instancesDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/landlord-owner`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.landlordOwnerDashboard
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/signature`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.signature,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/approval`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.approval,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/campaigns`:
            case `/orgs/${pathname.split('/')?.[2]}/campaign/${pathname.split('/')?.[4]}`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.campaignDashboard,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/clusters/dates`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.keyDate,
                    withHeader: true
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/documentation/collections`:
            case `/orgs/${pathname.split('/')?.[2]}/documentation/collections/current`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.norms
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/documentation/search/current`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.searchInNorms
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/documentation/norms-management`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.normsManagement
                });
                break;
            case `/orgs/${pathname.split('/')?.[2]}/quotation`:
                store.dispatch({
                    type: actionTypes.UPDATE_MENU_TITLE,
                    menuItem: EMenuItem.askQuotation,
                    withHeader: true
                });
                break;

            default:
                break;
        }
    }, [pathname]);

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.setProperty('overflow', 'hidden');
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [isMobileMenuOpen]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        dispatch(setExportView(params.get('export') === 'true'));
    }, [search, dispatch]);

    useEffect(() => {
        if (showWopi) {
            wopiFunc();
        }
    }, [showWopi]);

    useEffect(() => {
        const queryParam = queryString.parse(window.location.search);
        if (queryParam.wopieItemId) {
            dispatch<GetWopiInfo>(getWopiInfo(queryParam.wopieItemId as string, queryParam.edit === 'true' ? true : false)).then((res) => {
                setShowWopi(true);
                setWopiInfo(res);
            }).catch((err) => {
                addAlert(err, EAlertType.ERROR);
            });
        }
    }, [window.location.search]);

    const toggleMobileMenu = useCallback(() => setIsMobileMenuOpen(isOpen => !isOpen), []);

    const showNavigation = useMemo(() => !(!(isHomeRoute && !isLoggedIn) && (isTeamsConfigPage || isTeamsRoute || window.location.pathname === '/register' || window.location.pathname === '/registration-final-step')) && !isShadowAuth && !isInExportView, [isLoggedIn, isHomeRoute, isTeamsConfigPage, isInExportView]);

    return (
        <OverlayContextProvider>
            <PanelContainer />
            <LayoutWrapper isPrintLayout={isShadowAuth}>
                {showWopi ? (
                    <WopieWrapper>
                        <form id="office_form" name="office_form" target="office_frame" action={wopiInfo?.wopiSourceUrl} method="post" >
                            <input name="access_token" value={wopiInfo?.accessToken} type="hidden" />
                            <input name="access_token_ttl" value={wopiInfo?.accessTokenTtl} type="hidden" />
                            <input name="user_id" value={wopiInfo?.userId} type="hidden" />
                            <input name="owner_id" value={wopiInfo?.ownerId} type="hidden" />
                        </form>
                        <span id="frameholder" style={{ width: '500px', height: '550px' }}></span>
                    </WopieWrapper>
                ) : (
                    <>
                        {showNavigation && (
                            <TopBar onMobileMenuClick={toggleMobileMenu} isLoggedIn={isLoggedIn} clientLogo={CONFIG.mainConfig?.clientLogo} />
                        )}
                        {(!isShadowAuth && !isTeamsRoute && !isInExportView && window.location.pathname !== '/register' && window.location.pathname !== '/registration-final-step') &&
                            <SideBar isMobileMenuVisible={isMobileMenuOpen} />
                        }
                        <ContentWrapper withNavigation={showNavigation} isRegisterRoute={window.location.pathname === '/register' || window.location.pathname === '/registration-final-step'}>
                            <Content isRegisterRoute={window.location.pathname === '/register' || window.location.pathname === '/registration-final-step'}>
                                {Object.keys(translations).length > 0 && children}
                            </Content>
                        </ContentWrapper>
                    </>
                )}
            </LayoutWrapper>
        </OverlayContextProvider>
    );
};
