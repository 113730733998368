import React from 'react';
import styled, { css } from 'styled-components';

import { ESortTypes } from '../../../entities/AssetManagement/global';
import { IColumnSettings } from '../../../entities/IClusters';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { HeaderCell } from './HeaderCell';

const Wrapper = styled.thead<{ hasColoredColumn: boolean; whiteHeader: boolean }>`
    tr {
        th {
            position: sticky;
            top: 0;
            background: ${colorStack.bodyBg};
            z-index: 9;
            padding-inline-end: 0.5rem;
            padding-block-end: 0.25rem;
            color: ${colorStack.label};
            ${fontSizeAndHeight[13]};
            font-weight: 500;
            padding: 8px 8px 0 0;
            text-align: left;
            vertical-align: bottom;
            ${({ whiteHeader }) => whiteHeader && css`
                background: ${colorStack.white};
            `}
            ${({ hasColoredColumn }) => hasColoredColumn && css`
                &:first-child {
                    width: 8px;
                    min-width: 8px;
                    max-width: 8px;
                    padding: 8px 0;
                }
            `}
        }
    }
`;

const TableHead = styled.th<{ resizable: boolean }>`
    box-sizing: border-box;
    ${({ resizable }) => resizable && css`
        resize: horizontal;
        overflow: hidden;
    `}
`;

interface IHeaderProps {
    data: IColumnSettings[];
    directions?: Record<string, ESortTypes>;
    hasColoredColumn?: boolean;
    whiteHeader?: boolean;
    setDirection(order: ESortTypes, field: string);
}

export const Header = (props: IHeaderProps) => {
    return (
        <Wrapper hasColoredColumn={props.hasColoredColumn} whiteHeader={props.whiteHeader}>
            <tr>
                {props.hasColoredColumn && <th />}
                {props.data?.map((elem) => {
                    const { width, maxWidth, minWidth } = elem;
                    return (
                        <TableHead key={elem.field} style={{ width, maxWidth, minWidth }} resizable={elem.resizable} >
                            <HeaderCell
                                onSort={(direction) => props.setDirection(direction, elem.field)}
                                direction={props.directions?.[elem.field]}
                                headerText={elem.headerText}
                            />
                        </TableHead>);
                })}
            </tr>
        </Wrapper>
    );
};
