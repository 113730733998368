import * as actionTypes from '../actions/actionTypes/contextTypes';
import { IOrganizationContext } from '../entities/IContext';
import { instanceConfig } from '../instance';
import { EUserOrigin } from '../entities/ISoge';

export interface IContextReducer {
    pageContext: {
        web: {
            absoluteUrl: string;
            language: number;
        };
        user?: {
            displayName: string;
            email: string;
            department?: string;
            userOrigin?: EUserOrigin;
        };
        oid?: string;
    },
    isLoggedIn: boolean;
    isShadowAuth: boolean;
    isInExportView: boolean;
    instanceId: string;
    tokenExpiration: number;
    token: string;
    contextSet: boolean;
    passwordResetMode: boolean;
    googlePlacesLoaded: boolean;
    tokenReloading: boolean;
    tokenRenewalRequested: boolean;
    contextOrganizations: {
        ids: string[];
        byId: {
            [id: string]: IOrganizationContext;
        };
        byUrl: {
            [url: string]: IOrganizationContext;
        }
    };
    organizationsLoaded: boolean;
}

const defaultState = (): IContextReducer => ({
    pageContext: {
        web: {
            absoluteUrl: location.origin,
            language: 1033 // TODO: for this moment it's hard coded to show only EN language
        },
        user: undefined,
        oid: undefined
    },
    isLoggedIn: false,
    tokenExpiration: undefined,
    isShadowAuth: false,
    isInExportView: false,
    instanceId: 'LegalCluster',
    token: undefined,
    contextSet: false,
    passwordResetMode: false,
    googlePlacesLoaded: false,
    tokenReloading: true,
    tokenRenewalRequested: false,
    contextOrganizations: {
        ids: [],
        byId: {},
        byUrl: {}
    },
    organizationsLoaded: false
});

export default (state = defaultState(), action): IContextReducer => {
    switch (action.type) {
        case actionTypes.SET_LOGGED_IN_USER: {
            return {
                ...state,
                isLoggedIn: !!(action.user && action.accessToken),
                contextSet: true,
                token: action.accessToken,
                tokenExpiration: action.tokenExpiration,
                tokenReloading: false,
                pageContext: {
                    ...state.pageContext,
                    user: action.user,
                    oid: action.oid
                }
            };
        }

        case actionTypes.SET_PASSWORD_RESET_MODE: {
            return {
                ...state,
                passwordResetMode: action.passwordResetMode
            };
        }

        case actionTypes.CLEAR_USER_CONTEXT: {
            return {
                ...state,
                token: '',
                isLoggedIn: false,
                isShadowAuth: false,
                pageContext: {
                    ...state.pageContext,
                    user: undefined
                }
            };
        }

        case actionTypes.LOAD_EXTERNAL_GPLACES: {
            return {
                ...state,
                googlePlacesLoaded: true
            };
        }

        case actionTypes.SET_TOKEN_RELOAD_LOADER: {
            return {
                ...state,
                tokenReloading: action.loading
            };
        }

        case actionTypes.REQUEST_TOKEN_RENEWAL: {
            return {
                ...state,
                tokenRenewalRequested: true
            };
        }

        case actionTypes.LOAD_ORGANIZATION_CONTEXTS: {
            const organizations = (action.organizations || []) as IOrganizationContext[];
            const filteredOrganizations = !!instanceConfig.orgContextFlag
                ? organizations.filter(org => !!org.details[instanceConfig.orgContextFlag])
                : organizations;

            return {
                ...state,
                organizationsLoaded: true,
                contextOrganizations: {
                    ids: filteredOrganizations.map(organization => organization.id),
                    byId: filteredOrganizations.reduce((organizationsById, organization: IOrganizationContext) => ({
                        ...organizationsById,
                        [organization.id]: organization
                    }), {}),
                    byUrl: filteredOrganizations.reduce((organizationsByUrl, organization: IOrganizationContext) => ({
                        ...organizationsByUrl,
                        [organization.details.urlName]: organization
                    }), {})
                }
            };
        }

        case actionTypes.ADD_TAGS_TO_CONTEXT_ORGANIZATION: {
            const updatedOrg: IOrganizationContext = {
                ...state.contextOrganizations.byUrl[action.organizationUrl],
                details: {
                    ...state.contextOrganizations.byUrl[action.organizationUrl].details,
                    tags: [
                        ...(state.contextOrganizations.byUrl[action.organizationUrl].details.tags || []),
                        ...action.tags.map(tag => ({
                            name: tag,
                            status: 'Active'
                        }))
                    ]
                }
            };

            return {
                ...state,
                contextOrganizations: {
                    ...state.contextOrganizations,
                    byUrl: {
                        ...state.contextOrganizations.byUrl,
                        [updatedOrg.details.urlName]: updatedOrg
                    },
                    byId: {
                        ...state.contextOrganizations.byId,
                        [updatedOrg.id]: updatedOrg
                    }
                }
            };
        }

        case actionTypes.SET_ACCESS_TOKEN: {
            return {
                ...state,
                token: action.token,
                isLoggedIn: true,
                isShadowAuth: action.isShadowAuth
            };
        }

        case actionTypes.SET_EXPORT_VIEW: {
            return {
                ...state,
                isInExportView: action.isInExportView
            };
        }

        default: return state;
    }
};