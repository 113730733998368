export interface ISingleMessage { // TODO: mixed up properties from different interfaces - to refactor in future
    content: string;
    conversationId: string;
    createdDate: string;
    senderUserId: string;
    id: string;
    dataType: string;
    status: string;
    type: string; // TODO: why not MessageType?
    tempId: string;
    attachData: any;
    attachments: IAttachment[];
    additionalInformation: any;
}

export interface IAttachmentInfo {
    messageId: string;
    fieAttachment: IAttachment;
}

export interface IAttachment {
    fileType: string;
    name: string;
    size: number;
    uri: string;
}

export interface ICreateMessage {
    createDate: string;
    content: string;
    type: EAllMessageTypes;
    translationId: ETranslationKey;
    conversationId: string;
    conversationMembers: ConversationMember[];
    title: string;
    tempId: string; // "TempId is used only for front end app"
    id: string;
}

export interface Contact {
    cabinet: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    picture: string;
    id: string;
    check?: boolean;
    displayName: string;
}

export interface ConversationMember {
    canSeeMessagesBeforeJoin: boolean;
    id: string;
    displayName: string;
    picture: string;
    joinDate: string;
    cabinet: string;
    jobTitle: string;
    memberRole: number;
}

export interface ISingleConversation {
    title: string;
    type: EConversationType;
    id: string;
    creationDate: string;
    conversationMembers?: ConversationMember[];
    unreadMessagesCount: number;
    lastMessage: {
        creationDate: string;
        preview: string;
        messageId: string;
    }
}

export interface IMessageSearchResult {
    messages: ISingleMessage[];
    count: number;
}

export enum EMobileContext {
    Menu = 'Menu',
    Conversation = 'Conversation',
    Settings = 'Settings',
    Create = 'Create',
    Search = 'Search'
}

export enum EMessageType {
    Text = 'Text',
    Attachment = 'Attachment',
    Link = 'Link'
}

export enum ETriggerType {
    User = 'User',
    Scroller = 'Scroller'
}

export enum EConversationType {
    Individual = 'Individual',
    Group = 'Group',
    Channel = 'Channel',
    Diffusion = 'Diffusion',
    Topic = 'Topic'
}

export enum EMessageSystemTypes {
    SystemCreationConversation = 'SystemCreationConversation',
    SystemAddingMember = 'SystemAddingMember',
    SystemRemovingMember = 'SystemRemovingMember',
    SystemRemovingMessage = 'SystemRemovingMessage',
    SystemChangingTitle = 'SystemChangingTitle',
    SystemLeavingConversation = 'SystemLeavingConversation',
    SystemDeclaringOwner = 'SystemDeclaringOwner',
    SystemRevokingOwnerStatus = 'SystemRevokingOwnerStatus',
    System = 'System',
    SystemDocumentsUploaded = 'SystemDocumentsUploaded',
    SystemJoinMember = 'SystemJoinMember',
    RemovingFile = 'RemovingFile',
    RemovingMultipleFiles = 'RemovingMultipleFiles',
    RemovingVirtualFileSystem = 'RemovingVirtualFileSystem',
    RemovingMultipleVirtualFileSystem = 'RemovingMultipleVirtualFileSystem',
    UploadingVirtualFileSystem = 'UploadingVirtualFileSystem',
    UploadingMultipleVirtualFileSystem = 'UploadingMultipleVirtualFileSystem',
    UpdatingTeam = 'UpdatingTeam',
    UpdatingDynamicStakes = 'UpdatingDynamicStakes',
    EditingPresentationTeam = 'EditingPresentationTeam',
    ValidationRequestPreview = 'ValidationRequestPreview',
    Attachment = 'Attachment',
    AttachmentFiles = 'AttachmentFiles',
    TextFile = 'TextFile',
    UploadingFile = 'UploadingFile',
    UploadingMultipleFiles = 'UploadingMultipleFiles',
    Text = 'Text',
    UploadItems = 'UploadItems'
}

export enum EAllMessageTypes {
    Text = 0,
    System = 10,
    Attachment = 20,
    Link = 30,
    SystemCreationConversation = 40,
    SystemChangingTitle = 41,
    SystemLeavingConversation = 42,
    SystemRemovingMessage = 43,
    SystemJoinMember = 50,
    SystemAddingMember = 51,
    SystemRemovingMember = 52,
    SystemDeclaringOwner = 53,
    SystemRevokingOwnerStatus = 54,
    SystemDocumentsUploaded = 60,
    SystemDocumentRemoved = 61,
    UploadingVirtualFileSystem = 62,
    UploadingMultipleVirtualFileSystem = 63,
    RemovingVirtualFileSystem = 64,
    RemovingMultipleVirtualFileSystem = 65,
    EditingPresentationTeam = 70,
    EditingSpecificationTeam = 71,
    AddingMemberInInvolvedPartyGroupTeam = 72,
    EditingMemberInInvolvedPartyGroupTeam = 73,
    DeletingMemberFromInvolvedPartyGroupTeam = 74,
    AddingNewTimlineItemTeam = 75,
    EditingTimelineItemTeam = 76,
    MarkingTimelineItemAsReferenceDateTeam = 77,
    DeletingTimelineItemTeam = 78,
    EditingGeneralInfoOfLegalStakesTeam = 79,
    AddingNewLegalStakeTeam = 80,
    EditingLegalStakeTeam = 81,
    DeletingLegalStakeTeam = 82,
    AddingNewEconomicStakeTeam = 83,
    EditingEconomicStakeTeam = 84,
    DeletingEconomicStakeTeam = 85,
    UpdatingTeam = 90
}

export enum ETranslationKey {
    NotTranslatable = 0,
    ConversationCreated = 40,
    ConversationTitleChanged = 41,
    UserLeftConversation = 42,
    MessageRemoved = 43,
    UserLeftConversationNamedNewOwner = 44,
    UserRemovedFromConversation = 45,
    UserAddedToConversation = 46,
    MessageRemovedBySender = 47,
    MemberJoined = 50,
    MemberAdded = 51,
    MemberRemoved = 52,
    OwnerDeclared = 53,
    OwnerStatusRevoked = 54,
    MemberLeft = 55,
    DocumentUploaded = 60,
    DocumentRemoved = 61,
    UploadingVirtualFileSystem = 62,
    UploadingMultipleVirtualFileSystem = 63,
    RemovingVirtualFileSystem = 64,
    RemovingMultipleVirtualFileSystem = 65,
    EditingPresentationTeam = 70,
    EditingSpecificationTeam = 71,
    AddingMemberInInvolvedPartyGroupTeam = 72,
    EditingMemberInInvolvedPartyGroupTeam = 73,
    DeletingMemberFromInvolvedPartyGroupTeam = 74,
    AddingNewTimlineItemTeam = 75,
    EditingTimelineItemTeam = 76,
    MarkingTimelineItemAsReferenceDateTeam = 77,
    DeletingTimelineItemTeam = 78,
    EditingGeneralInfoOfLegalStakesTeam = 79,
    AddingNewLegalStakeTeam = 80,
    EditingLegalStakeTeam = 81,
    DeletingLegalStakeTeam = 82,
    AddingNewEconomicStakeTeam = 83,
    EditingEconomicStakeTeam = 84,
    DeletingEconomicStakeTeam = 85,
    UpdatingTeam = 90
}

export enum EChannelTitles {
    General = 'General'
}
