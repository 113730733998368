import React, { FC, useEffect } from 'react';
import queryString from 'query-string-for-all';
import { useSelector } from 'react-redux';

import { GlobalLoader } from '../GlobalLoader';
import { IState } from '../../reducers';
import { IConfigReducer } from '../../reducers/configReducer';
import { EAuthConstants } from '../../tools/authTools';
import { localStorage } from '../../tools/storage';
import { instanceConfig } from '../../instance';

const { mainConfig } = instanceConfig;

export const Logout: FC<React.PropsWithChildren<unknown>> = () => {
    const { appConfig: { applicationConfig } } = useSelector<IState, IConfigReducer>(state => state.config);
    const teams = mainConfig.clientName === 'teams';

    useEffect(() => {
        localStorage.removeItem(EAuthConstants.SessionKey);
        localStorage.removeItem(EAuthConstants.ACKey);
        localStorage.removeItem(EAuthConstants.IDKey);

        const queryParams = {
            post_logout_redirect_uri: !teams ? applicationConfig.redirectURI : `${window.location.origin}/teams/logout-end`
        };
        const logoutEndpoint = `${applicationConfig.authority}/oauth2/v2.0/logout?${queryString.stringify(queryParams)}`;

        window.location.assign(logoutEndpoint);
    }, []);

    return (
        <GlobalLoader isLoading />
    );
};
