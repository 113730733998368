import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string-for-all';

import { GlobalLoader } from '../GlobalLoader';
import { IState } from '../../reducers';
import { IConfigReducer } from '../../reducers/configReducer';
import { uuidv4, EAuthConstants, AuthState, getContextUserId } from '../../tools/authTools';
import { storeWithExpiry, getWithExpiry } from '../../tools/storage';

interface IAuthStartProps {
    passwordResetMode?: boolean;
}

export const AuthStart: FC<React.PropsWithChildren<IAuthStartProps>> = ({ passwordResetMode }) => {
    const { appConfig: { applicationConfig } } = useSelector<IState, IConfigReducer>(state => state.config);

    useEffect(() => {
        const query = queryString.parse(window.location.search);
        const nextRequestUrl = ((query.nextRequestUrl as string || '').match('auth-callback') ? '/' : query.nextRequestUrl as string) || '/';

        const userId = getContextUserId();
        const nonce = uuidv4();
        const ttlCsrf = getWithExpiry(EAuthConstants.CSRFState);
        const csrf = ttlCsrf || uuidv4();
        const stateObject: AuthState = { csrf, nextRequestUrl };
        const state = btoa(JSON.stringify(stateObject));

        !ttlCsrf && storeWithExpiry(EAuthConstants.CSRFState, csrf, 1000 * 60 * 3); // 3 min

        const queryParams = {
            client_id: applicationConfig.clientID,
            response_type: 'id_token token',
            response_mode: 'fragment',
            scope: `${applicationConfig.b2cScopes[0]} openid profile`,
            redirect_uri: `${applicationConfig.redirectURI}${EAuthConstants.RequestAuthEndRoute}`,
            nonce,
            state,
            domain_hint: 'organizations',
            ...(!!userId ? { login_hint: userId.email } : {})
        };

        const authority = passwordResetMode ? applicationConfig.authorityResetPassword : applicationConfig.authority;
        const authorizeEndpoint = `${authority}/oauth2/v2.0/authorize?${queryString.stringify(queryParams)}`;

        window.location.assign(authorizeEndpoint);
    }, []);

    return (
        <GlobalLoader isLoading />
    );
};
