import { useState, useEffect } from 'react';
import { uniqBy } from 'lodash';

import { INotification } from '../../../entities/INotification';
import { ISearchResponse } from '../../../entities/IGlobal';

interface INotificationHookProps {
    notificationsCount: number;
    showNotificationList: boolean;
    navMenuNotifications: INotification[];
    loadNotifications(page: number, size?: number): Promise<ISearchResponse<INotification>>;
    markAllAsNotNew(): void;
    updateNavMenuNotifications(notifications: INotification[]): void;
}

export const NotificatinHook = (props: INotificationHookProps) => {
    const {
        showNotificationList, notificationsCount, markAllAsNotNew,
        loadNotifications, updateNavMenuNotifications, navMenuNotifications
    } = props;
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setsHasMore] = useState<boolean>(true);

    useEffect(() => {
        showNotificationList && notificationsCount > 0 && markAllAsNotNew();
    }, [showNotificationList, notificationsCount]);

    const onScrollResults = (page: number = 0) => {
        loadNotifications(page)
            .then(result => {
                const newList = uniqBy([...navMenuNotifications, ...(result?.items || [])], 'id');
                setCurrentPage(page);
                updateNavMenuNotifications(newList);
                setsHasMore(result.itemsOnPageCount === result.size);
            });
    };

    useEffect(() => onScrollResults(), []);

    return {
        onScrollResults,
        hasMore,
        currentPage
    };
};