import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { CookieMain, CookieWrapper, ButtonWrapper, Title, Description, DescriptionWrapper, Close, Learn, Accept } from './Components';
import { setCookie, getCookie } from '../../tools/cookies';
import { instanceConfig } from '../../instance';
import { IState } from '../../reducers/index';
import { ICookiesReducer } from '../../reducers/cookiesReducer';
import { IProfileReducer } from '../../reducers/profileReducer';
import { IContextReducer } from '../../reducers/contextReducer';
import * as cookiesActions from '../../actions/cookiesAction';

const { enableTracking } = instanceConfig;

// tslint:disable-next-line: no-var-requires
const { reactAI, withAITracking } = enableTracking && require('react-appinsights');

export interface CookiesState {
    openLearn: boolean;
    openHeight: boolean;
    fadeOut: boolean;
}

type CookieProps = ICookiesReducer & IContextReducer & IProfileReducer & typeof cookiesActions;

class Cookies extends React.Component<CookieProps, CookiesState> {

    componentWillMount() {
        instanceConfig.enableTracking && reactAI.setContext({ CorrelationId: `${this.props.currentUserProfile ? this.props.currentUserProfile.id : ''}`, ComponentName: 'Cookies', url: location.href });
    }

    componentDidMount() {
        this.checkCookie();
    }

    checkCookie = () => {
        const lcrCookie = getCookie('lcrCookie');
        if (lcrCookie === '') {
            this.props.showCookiesAction();
            this.props.blockCookieAction();
        } else {
            this.props.hideCookiesAction();
            this.props.unblockCookieAction();
        }
    }

    accept = () => {
        setCookie('lcrCookie', true, 395);
        this.props.unblockCookieAction();
        this.props.hideCookiesAction();
    }

    public render() {
        const { showCookie, isShadowAuth } = this.props;
        return (
            <div>
                 {showCookie && !isShadowAuth &&
                    <CookieMain >
                        <CookieWrapper >
                            <Close onClick={this.accept} />
                                <div>
                                    <Title><FormattedMessage id="cookies.privacy" /></Title>
                                    <DescriptionWrapper>
                                        <Description>
                                            <FormattedMessage id="cookies.message" />
                                            <button onClick={this.accept}>
                                                <Learn href={'/copyrights'} target="_blank" ><FormattedMessage id="cookies.button.learnMore" /></Learn>
                                            </button>
                                        </Description>
                                        <ButtonWrapper>
                                            <Accept onClick={this.accept}><FormattedMessage id="cookies.button.accept" /></Accept>
                                        </ButtonWrapper>
                                    </DescriptionWrapper>
                                </div>
                        </CookieWrapper>
                    </CookieMain>
                }
            </div>
        );
    }
}

export default connect(
    (state: IState) => ({
        ...state.cookies,
        ...state.context,
        ...state.profile
    }),
    cookiesActions
)(instanceConfig.enableTracking ? withAITracking(Cookies) as typeof Cookies : Cookies);