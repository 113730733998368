import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ISIngleLegalEntity } from '../../../../../entities/ILegalEntities';
import { EProfileType, IUserBrief } from '../../../../../entities/IGlobal';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';
import { Alert } from '../../../../Alert/Alert';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';
import { IState } from '../../../../../reducers';
import { IProfileReducer } from '../../../../../reducers/profileReducer';
import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { getProfileById } from '../../../../../actions/profileActions';

type GetProfileById = ReturnType<typeof getProfileById>;

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem
    }
`;

export const Step4: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
    }, []);

    useEffect(() => {
        if (!eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation) {
            eApprovalContext.modifyData({
                firstName: currentUserProfile?.firstName,
                lastName: currentUserProfile?.lastName,
                jobTitle: '',
                picture: currentUserProfile?.picture,
                cabinet: '',
                emailContact: '',
                id: currentUserProfile?.id
            }, 'creator', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData(currentUserProfile?.jobTitle, 'jobTitle', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData({
                firstName: currentUserProfile?.manager?.firstName,
                lastName: currentUserProfile?.manager?.lastName,
                jobTitle: currentUserProfile?.manager?.jobTitle,
                picture: currentUserProfile?.manager?.picture,
                cabinet: '',
                emailContact: '',
                id: currentUserProfile?.manager?.id
            }, 'manager', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData({
                name: currentUserProfile?.cabinet?.name,
                picture: currentUserProfile?.cabinet?.picture,
                id: currentUserProfile?.cabinet?.id,
                type: EProfileType.Company
            }, 'legalEntity', props.context, props.stepName, props.stepNumber);
        }
    }, []);

    const onChangeSelectedEntity = (element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'legalEntity', props.context, props.stepName, props.stepNumber);
    };

    const onChangeManager = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'manager', props.context, props.stepName, props.stepNumber);
    };

    const onChangeCreator = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'creator', props.context, props.stepName, props.stepNumber);
        if (element?.[0]?.data?.id) {
            dispatch<GetProfileById>(getProfileById(element?.[0]?.data?.id)).then(res => {
                eApprovalContext.modifyData(res?.jobTitle, 'jobTitle', props.context, props.stepName, props.stepNumber);
                eApprovalContext.modifyData(res?.manager ? {
                    firstName: res?.manager?.firstName,
                    lastName: res?.manager?.lastName,
                    jobTitle: res?.manager?.jobTitle,
                    picture: res?.manager?.picture,
                    cabinet: '',
                    emailContact: '',
                    id: res?.manager?.id
                } : undefined, 'manager', props.context, props.stepName, props.stepNumber);
                eApprovalContext.modifyData(res?.cabinet ? {
                    name: res?.cabinet?.name,
                    picture: res?.cabinet?.picture,
                    id: res?.cabinet?.id,
                    type: EProfileType.Company
                } : undefined, 'legalEntity', props.context, props.stepName, props.stepNumber);
            });
        }
        if (!element?.[0]) {
            eApprovalContext.modifyData('', 'jobTitle', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData(undefined, 'manager', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData(undefined, 'legalEntity', props.context, props.stepName, props.stepNumber);
        }
    };

    const onChangeJobTitle = (value: string) => {
        eApprovalContext.modifyData(value, 'jobTitle', props.context, props.stepName, props.stepNumber);
    };

    return (
        <Wrapper>
            <InnerWrapper>
                <Row noBorder numberOfElements={2}>
                    <UsersPicker
                        value={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.creator?.id ? [{
                            key: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.creator?.id,
                            text: `${eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.creator?.firstName}
                                ${eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.creator?.lastName}`,
                            data: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.creator
                        }] : undefined}
                        onSelectElement={onChangeCreator}
                        required
                        label={<FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.FreeDeclaration]}.creator`} />}
                    />
                    <TextComponent
                        value={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.jobTitle}
                        onChange={onChangeJobTitle}
                        label={<FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.FreeDeclaration]}.jobTitle`} />}
                    />
                </Row>
                <Row noBorder numberOfElements={2}>
                    <LegalEntitiesPicker
                        value={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.legalEntity?.id ? [{
                            key: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.legalEntity?.id,
                            text: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.legalEntity?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.legalEntity
                        }] : undefined}
                        onSelectElement={onChangeSelectedEntity}
                        filteredCompanies
                        required
                        label={<FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.FreeDeclaration]}.legalEntity`} />}
                    />
                    <UsersPicker
                        value={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.manager?.id ? [{
                            key: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.manager?.id,
                            text: `${eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.manager?.firstName}
                                    ${eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.manager?.lastName}`,
                            data: eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.creatorInformation?.manager
                        }] : undefined}
                        onSelectElement={onChangeManager}
                        required
                        label={<FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.FreeDeclaration]}.manager`} />}
                    />
                </Row>
            </InnerWrapper>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.INFO}
            >
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `eApproval.wizard.step5.${[EApprovalTypes.FreeDeclaration]}.alert1.description` }) }} />
            </Alert>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.DEFAULT}
            >
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `eApproval.wizard.step5.${[EApprovalTypes.FreeDeclaration]}.alert2.description` }) }} />
            </Alert>
        </Wrapper>
    );
};
