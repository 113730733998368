import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { LineSeparator } from '../LineSeparator/LineSeparator';

const Wrapper = styled.div<{noBorder: boolean}>`
    display: flex;
    flex-direction: column;
    ${({noBorder}) => !noBorder && css`
        margin: 0 0 1rem 0;
        padding: 0 0 1rem 0;
    `}
`;

const Content = styled.div<IProps>`
    margin: 0 0 1rem 0;
    display: flex;
    justify-content: space-between;
    > div {
        width: 100%;
        ${props => props.numberOfElements > 1 && css`
            width: ${`${(100 / props.numberOfElements) - 1}%`};
        `}
    }
    &:last-child {
        border: none;
        margin: 0;
        padding: 0;
    }
`;

interface IProps {
    numberOfElements: number;
    noBorder?: boolean;
}

export const Row: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper noBorder={props.noBorder}>
            <Content numberOfElements={props.numberOfElements} noBorder={props.noBorder}>
                {props.children}
            </Content>
            {!props.noBorder &&
                <LineSeparator />
            }
        </Wrapper>
    );
};