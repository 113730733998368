import React, { FC, useCallback, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { cardsShadow } from '../../styleHelpers/mixins/shadow';
import { colorStack } from '../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../styleHelpers/fontSizes';
import { EAlertType, EAlertTypeSchema } from '../../entities/IAlert';
import { hideAndRemoveAlert } from '../../actions/alertActions';

const progressAnimation = keyframes`
    from {
        width: 100%;
    }

    to {
        width: 0;
    }
`;

const Progress = styled.div`
    height: 16px;
    animation: ${progressAnimation} 10s linear;
    animation-fill-mode: forwards;
`;

interface IAlertWrapperProps {
    alertType: EAlertType;
    isVisible: boolean;
    isPaused: boolean;
    typeSchema: EAlertTypeSchema;
    withoutProgress: boolean
}

const ContentInner = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
`;

const Wrapper = styled.div<IAlertWrapperProps>`
    margin-top: 1rem;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    ${({ withoutProgress }) => !withoutProgress && css`
        ${cardsShadow()};
        transform: translateX(200%);
        transition: transform 600ms;
    `}
    ${props => props.isVisible && css`
        transform: translateX(0);
    `}
    ${Progress} {
        background: ${colorStack.middleBlue};
    };
    ${({ alertType, typeSchema }) => alertType === EAlertType.SUCCESS && css`
        ${Progress} {
            background: ${colorStack.middleGreen};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            ${Progress} {
                background: ${colorStack.middleGreen};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.DONE && css`
        ${Progress} {
            background: ${colorStack.middlePurple};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            ${Progress} {
                background: ${colorStack.middlePurple};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.WARNING && css`
        ${Progress} {
            background: ${colorStack.middleOrange};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            ${Progress} {
                background: ${colorStack.middleOrange};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.ERROR && css`
        ${Progress} {
            background: ${colorStack.middleRed};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            ${Progress} {
                background: ${colorStack.middleRed};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.INFO && css`
        ${Progress} {
            background: ${colorStack.middleBlue};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            ${Progress} {
                background: ${colorStack.middleBlue};
            }
        `}
    `};
    &:hover {
        ${Progress} {
            animation-play-state: paused;
        }
    }
    ${props => props.isPaused && css`
        ${Progress} {
            animation-play-state: paused;
        }
    `}
`;

const LinkName = styled.div<{ alertType: EAlertType, typeSchema: EAlertTypeSchema }>`
    margin-top: 1rem;
    font-weight: 500;
    ${fontSizeAndHeight[20]};
    color: ${colorStack.middleBlue};
    ${({ alertType }) => alertType !== EAlertType.DEFAULT && css`
        color: ${colorStack.white};
    `};
    ${({ typeSchema }) => typeSchema === EAlertTypeSchema.SECONDARY && css`
        color: ${colorStack.middleBlue};
    `};
`;

const Content = styled.div<{ alertType: EAlertType, typeSchema: EAlertTypeSchema }>`
    display: flex;
    padding-top: 2px;
    ${fontSizeAndHeight[16]};
    flex-direction: column;
    ${({ alertType }) => alertType !== EAlertType.DEFAULT && css`
        color: ${colorStack.white};
    `};
    ${({ typeSchema }) => typeSchema === EAlertTypeSchema.SECONDARY && css`
        color: ${colorStack.content};
    `};
`;

const ProgressWrapper = styled.div`
    height: 16px;
    background: ${colorStack.ligthGrey};
`;

const CloseBtn = styled.button<{ alertType: EAlertType, typeSchema: EAlertTypeSchema }>`
    cursor: pointer;
    outline: 0;
    margin: 0 0 0 auto;
    font-size: 16px;
    color: ${colorStack.darkGrey};
    ${({ alertType, typeSchema }) => alertType !== EAlertType.DEFAULT && css`
        color: ${colorStack.white};
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            color: ${colorStack.darkGrey};
        `}
    `};
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    padding-top: 3px;
    margin-bottom: 1rem;
    height: 23px;
    width: 100%;
`;

const Title = styled.div<{ alertType: EAlertType, typeSchema: EAlertTypeSchema }>`
    font-weight: 500;
    ${fontSizeAndHeight[16]};
    color: ${colorStack.content};
    ${({ alertType }) => alertType !== EAlertType.DEFAULT && css`
        color: ${colorStack.white};
    `};
    ${({ typeSchema }) => typeSchema === EAlertTypeSchema.SECONDARY && css`
        color: ${colorStack.content};
    `};
`;

const Left = styled.div<{ alertType: EAlertType, typeSchema: EAlertTypeSchema }>`
    width: 24px;
    padding: .9rem;
    margin-right: 1rem;
    svg {
        flex-shrink: 0;
        margin-right: 1rem;
        font-size: 24px;
        color: ${colorStack.white};
        ${props => props.alertType === EAlertType.DEFAULT && css`
            color: ${colorStack.darkBlue};
        `}
        ${props => (props.typeSchema === EAlertTypeSchema.SECONDARY && (props.alertType === EAlertType.DEFAULT || props.alertType === EAlertType.INFO)) && css`
            color: ${colorStack.darkBlue};
        `}
        ${props => (props.typeSchema === EAlertTypeSchema.SECONDARY && props.alertType === EAlertType.ERROR) && css`
            color: ${colorStack.darkRed};
        `}
        ${props => (props.typeSchema === EAlertTypeSchema.SECONDARY && props.alertType === EAlertType.SUCCESS) && css`
            color: ${colorStack.darkGreen};
        `}
        ${props => (props.typeSchema === EAlertTypeSchema.SECONDARY && props.alertType === EAlertType.DONE) && css`
            color: ${colorStack.darkPurple};
        `}
        ${props => (props.typeSchema === EAlertTypeSchema.SECONDARY && props.alertType === EAlertType.WARNING) && css`
            color: ${colorStack.darkOrange};
        `}
    }
`;

const Right = styled.div<{ fullSize: boolean }>`
    width: calc(100% - 1rem - 24px);
    padding: 1rem;
    ${props => props.fullSize && css`
        width: 100%;
    `};
`;

const MainContent = styled.div<{ typeSchema: EAlertTypeSchema, alertType: EAlertType, transparentRight: boolean }>`
    display: flex;
    align-items: center;
    background: ${colorStack.whiteGrey};
    ${Progress} {
        background: ${colorStack.middleBlue};
    };
    ${({ alertType, typeSchema }) => alertType === EAlertType.SUCCESS && css`
        background: ${colorStack.darkGreen};
        ${Progress} {
            background: ${colorStack.middleGreen};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            background: ${colorStack.lightGreen};
            ${Progress} {
                background: ${colorStack.middleGreen};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.WARNING && css`
        background: ${colorStack.darkOrange};
        ${Progress} {
            background: ${colorStack.middleOrange};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            background: ${colorStack.lightOrange};
            ${Progress} {
                background: ${colorStack.middleOrange};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.ERROR && css`
        background: ${colorStack.darkRed};
        ${Progress} {
            background: ${colorStack.middleRed};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            background: ${colorStack.lightRed};
            ${Progress} {
                background: ${colorStack.middleRed};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.DONE && css`
        background: ${colorStack.darkBlue};
        ${Progress} {
            background: ${colorStack.middlePurple};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            background: ${colorStack.lightPurple};
            ${Progress} {
                background: ${colorStack.middlePurple};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.INFO && css`
        background: ${colorStack.darkBlue};
        ${Progress} {
            background: ${colorStack.middleBlue};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            background: ${colorStack.lightBlue};
            ${Progress} {
                background: ${colorStack.middleBlue};
            }
        `}
    `};
    ${({ alertType, typeSchema }) => alertType === EAlertType.DEFAULT && css`
        background: ${colorStack.whiteGrey};
        ${Progress} {
            background: ${colorStack.middleBlue};
        }
        ${typeSchema === EAlertTypeSchema.SECONDARY && css`
            background: ${colorStack.whiteGrey};
            ${Progress} {
                background: ${colorStack.middleBlue};
            }
        `}
    `};
    ${({transparentRight}) => transparentRight && css`
        > div:nth-child(2) {
            background: ${colorStack.whiteGrey};
        }
    `}
`;

interface IAlertProps {
    type: EAlertType;
    typeSchema?: EAlertTypeSchema;
    visible: boolean;
    title?: string;
    alertId: string | number;
    linkName?: string;
    withoutIco?: boolean;
    withoutProgress?: boolean;
    children: React.ReactNode;
    withoutClose?: boolean;
    transparentRight?: boolean;
    onLinkClick?();
}

export const Alert: FC<React.PropsWithChildren<IAlertProps>> = props => {
    const dispatch = useDispatch();
    const [paused, setPaused] = useState<boolean>(false);

    const hideAlert = useCallback(() => {
        dispatch(hideAndRemoveAlert(props.alertId));
    }, [props.alertId]);

    const onClick = useCallback(() => {
        setPaused(state => !state);
    }, []);

    return (
        <Wrapper
            alertType={props.type}
            isVisible={props.visible}
            withoutProgress={props.withoutProgress}
            isPaused={paused}
            onClick={onClick}
            typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY}
        >
            <MainContent typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY} alertType={props.type} transparentRight={props.transparentRight}>
                {!props.withoutIco && (
                    <Left
                        alertType={props.type}
                        typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} color={colorStack.darkBlue} size="lg" />
                    </Left>
                )}
                <Right fullSize={props.withoutIco}>
                    {props.title &&
                        <Top>
                            <Title
                                alertType={props.type || EAlertType.DEFAULT}
                                typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY}
                            >
                                {props.title}
                            </Title>
                            {!props.withoutClose &&
                                <CloseBtn
                                    onClick={hideAlert}
                                    className="js-alert-close"
                                    alertType={props.type || EAlertType.DEFAULT}
                                    typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </CloseBtn>
                            }
                        </Top>
                    }
                    <Content
                        alertType={props.type || EAlertType.DEFAULT}
                        typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY}
                    >
                        <ContentInner>
                            <div>
                                {props.children}
                            </div>
                            {(!props.withoutClose && !props.title) &&
                                <CloseBtn
                                    onClick={hideAlert}
                                    className="js-alert-close"
                                    alertType={props.type || EAlertType.DEFAULT}
                                    typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </CloseBtn>
                            }
                        </ContentInner>
                        {props.linkName &&
                            <LinkName
                                alertType={props.type || EAlertType.DEFAULT}
                                typeSchema={props.typeSchema || EAlertTypeSchema.PRIMARY}
                                onClick={props.onLinkClick}
                            >
                                {props.linkName}
                            </LinkName>
                        }
                    </Content>
                </Right>
            </MainContent>
            {!props.withoutProgress &&
                <ProgressWrapper>
                    <Progress onAnimationEnd={hideAlert} />
                </ProgressWrapper>
            }
        </Wrapper>
    );
};
