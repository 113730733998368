import React, { ReactNode, FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { EPanelType, EPanelUrlParams } from '../../Panel/PanelContainer';

const HyperLink = styled(Link)`
    display: inline-flex;
    font-weight: 400;
    color: ${colorStack.darkBlue};
    gap: 0.5rem;
    &:hover {
        text-decoration: underline;
    }
`;

export const enum ERouteName {
    CLUSTERDOCUMENTS = 'ClusterDocuments',
    CLUSTERACTIVITY = 'ClusterActivity',
    CLUSTERNAME = 'ClusterName',
    APPROVAL = 'Approval',
    KEYDATE = 'KeyDate'
}

interface IApproval {
    hyperlinkParameters: {
        clusterId: string;
        validationId: string;
        organizationUrlName: string;
        organizationId: string;
    }
    children: ReactNode;
}

interface IActivity {
    hyperlinkParameters: {
        id: string;
        organizationUrlName: string;
    }
    children: ReactNode;
}

interface IClusterName {
    hyperlinkParameters: {
        id: string;
        organizationUrlName: string;
    }
    children: ReactNode;
}

interface IClusterDocuments {
    hyperlinkParameters: {
        id: string;
        organizationUrlName: string;
    }
    children: ReactNode;
}

interface IKeyDate {
    hyperlinkParameters: {
        id: string;
    }
    children: ReactNode;
}

const ClusterName = (props: IClusterDocuments) => {
    return (
        <HyperLink to={`/orgs/${props.hyperlinkParameters.organizationUrlName}/cluster/${props.hyperlinkParameters.id}`}>
            {props.children}
        </HyperLink>
    );
};

const KeyDate = (props: IKeyDate) => {
    return (
        <HyperLink to={`${window.location.href}?keyDateId=${props.hyperlinkParameters.id}`}>
            {props.children}
        </HyperLink>
    );
};

const ClusterDocuments = (props: IClusterDocuments) => {
    return (
        <HyperLink to={`/orgs/${props.hyperlinkParameters.organizationUrlName}/cluster/${props.hyperlinkParameters.id}/documents`}>
            {props.children}
        </HyperLink>
    );
};

const Approval = (props: IApproval) => {
    return (
        <HyperLink to={`${window.location.href}?${EPanelUrlParams.PANELTYPE}=${EPanelType.APPROVAL}&${EPanelUrlParams.VALIDATIONID}=${props.hyperlinkParameters.validationId}&${EPanelUrlParams.CLUSTERID}=${props.hyperlinkParameters.clusterId}`}>
            {props.children}
        </HyperLink>
    );
};

const Activity = (props: IActivity) => {
    return (
        <HyperLink to={`/orgs/${props.hyperlinkParameters.organizationUrlName}/cluster/${props.hyperlinkParameters.id}/activity`}>
            {props.children}
        </HyperLink>
    );
};

interface HyperLinkSwitcherProps {
    data: IApproval | IActivity | IClusterDocuments | IClusterName | IKeyDate;
    routeName: ERouteName;
    children: ReactNode;
}

export const HyperLinkSwitcher: FC<HyperLinkSwitcherProps> = (props) => {

    const routNameMapper = (routName: ERouteName, data: IApproval | IActivity | IClusterDocuments | IClusterName | IKeyDate) => {
        switch (routName) {
            case ERouteName.APPROVAL:
                return <Approval
                    children={props.children}
                    hyperlinkParameters={(data as IApproval).hyperlinkParameters}
                />;
            case ERouteName.CLUSTERACTIVITY:
                return <Activity
                    children={props.children}
                    hyperlinkParameters={(data as IActivity).hyperlinkParameters}
                />;
            case ERouteName.CLUSTERNAME:
                return <ClusterName
                    children={props.children}
                    hyperlinkParameters={(data as IClusterName).hyperlinkParameters}
                />;
            case ERouteName.CLUSTERDOCUMENTS:
                return <ClusterDocuments
                    children={props.children}
                    hyperlinkParameters={(data as IClusterDocuments).hyperlinkParameters}
                />;
            case ERouteName.KEYDATE:
                return <KeyDate
                    children={props.children}
                    hyperlinkParameters={(data as IKeyDate).hyperlinkParameters}
                />;
            default:
                // tslint:disable-next-line: no-null-keyword
                return null;
        }
    };

    return (
        <>
            {routNameMapper(props.routeName, props.data)}
        </>
    );
};
