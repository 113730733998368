import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[16]};

    .cluster-name {
        color: ${colorStack.middleBlue};
    }

    span:not(.cluster-name) {
        margin: 1rem 0.5rem;
    }
`;

const Preview = styled.div`
    margin-top: 1rem;

    img {
        display: block;
        border: none;
        margin: 0 auto;
        width: 80%;
    }
`;

interface IPOAClusterCreatedStep {
    clusterName: string;
}

const previewSrc = require('../../../../../wwwroot/assets/images/1024/cluster-preview.png');

export const POAClusterCreatedStep: FC<React.PropsWithChildren<IPOAClusterCreatedStep>> = ({ clusterName }) => {
    const intl = useIntl();
    return (
        <Wrapper>
            <span><FormattedMessage id="poa.wizard.step2.text.great" /></span>
            <span dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'poa.wizard.step2.text.success' }, {
                    clusterName: clusterName,
                    span: (...chunks) => `<span class="cluster-name">${chunks}</span>`
                })
            }} />
            <Preview>
                <img src={previewSrc} />
            </Preview>
        </Wrapper>
    );
};
