import React, { FC, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { colorStack } from '../../../../../styleHelpers/colors';
import { ISIngleLegalEntity } from '../../../../../entities/ILegalEntities';
import { IUserBrief } from '../../../../../entities/IGlobal';
import { ISponsoringData } from '../../../../../entities/ActionData/ISponsoringData';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { Avatar } from '../../../../Common/Avatar/Avatar';
import { headersShadow } from '../../../../../styleHelpers/mixins/shadow';
import { RequiredInformation } from '../../../../Common/RequiredInformation/RequiredInformation';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { Alert } from '../../../../Alert/Alert';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';
import { MandatoryFieldStar } from '../../../../Common/MandatoryFieldStar/MandatoryFieldStar';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    position: relative;
    background: white;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem 0;
`;

const InputSection = styled.div<{ row?: boolean, text?: boolean, radio?: boolean }>`
    width: 50%;
    ${(props) =>
        props.row &&
        css`
            display: flex;
            flex-direction: row;
        `}
    ${(props) => props.text && css`
            display: inline-flex;
            align-items: center;
        `}
    ${(props) => props.radio && css`
            label {
                margin-right: 29px;
            }
        `}
`;

const Title = styled.div`
    color: ${colorStack.content};
    margin: 0 0 2rem 0;
    sup {
        color: ${colorStack.red};
    }
`;

const SingleBlockedElement = styled.div`
    height: 33px;
    padding: 0.2em;
    border-radius: 4px;
    span {
        width: 50%;
        display: block;
        border-radius: 10px;
        padding: 0 7px 0 0;
        margin-left: 7px;
    }
`;

export const Step4: FC<React.PropsWithChildren<unknown>> = () => {
    const intl = useIntl();
    const { setFieldValue, values } = useFormikContext<ISponsoringData>();

    useEffect(() => {
        setFieldValue('step', 4);
    }, []);

    const onChangeManagerSelected = useCallback((element: { key: string; text: string; data: IUserBrief; }[]) => {
        setFieldValue(`formData.information.manager`, element ? {
            firstName: element?.[0]?.data?.firstName || '',
            lastName: element?.[0]?.data?.lastName || '',
            picture: element?.[0]?.data?.picture,
            id: element?.[0]?.data?.id
            // tslint:disable-next-line:no-null-keyword
        } : null);

    }, [values.formData.information.manager?.firstName, setFieldValue]);

    const fillLegalEntityInformation = useCallback((data: ISIngleLegalEntity) => {
        setFieldValue(`formData.information.legalEntity`, {
            name: data?.name,
            picture: data?.picture,
            id: data?.id,
            type: data?.type
        });
    }, [setFieldValue]);

    const onChangeSelectedEntity = useCallback((element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        fillLegalEntityInformation(element?.[0]?.data);
    }, []);

    return (
        <Wrapper>
            <InnerWrapper>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <FormattedMessage id="sponsoring.popup.step4.text1" />
                        </LabelWithEllipsis>
                        <SingleBlockedElement>
                            <Avatar
                                picture={values.formData?.information?.creator.picture}
                                firstName={values.formData?.information?.creator.firstName}
                                lastName={values.formData?.information?.creator.lastName}
                                id={values.formData?.information?.creator.id}
                                extended
                            />
                        </SingleBlockedElement>
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis>
                            <FormattedMessage id="sponsoring.popup.step4.text2" />
                        </LabelWithEllipsis>
                        <TextComponent
                            value={values.formData.information.jobTitle}
                            onChange={(value: string) => setFieldValue('formData.information.jobTitle', value)}
                        />
                    </InputSection>
                </Row>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <FormattedMessage id="sponsoring.popup.step4.text3" />
                        </LabelWithEllipsis>
                        <LegalEntitiesPicker
                            value={values.formData?.information?.legalEntity?.id ? [{
                                key: values.formData?.information?.legalEntity?.id || '',
                                text: values.formData?.information?.legalEntity?.name || '',
                                data: values.formData?.information?.legalEntity || ''
                            }] : undefined}
                            onSelectElement={onChangeSelectedEntity}
                            filteredCompanies
                            hideButtons
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <FormattedMessage id="sponsoring.popup.step4.text4" />
                        </LabelWithEllipsis>
                        <UsersPicker
                            value={values.formData.information?.manager?.id ? [{
                                key: values.formData.information?.manager?.id || '',
                                text: values.formData.information?.manager?.firstName ? `${values.formData.information?.manager?.firstName} ${values.formData.information?.manager?.lastName}` : '',
                                data: {
                                    firstName: values.formData.information?.manager?.firstName || '',
                                    lastName: values.formData.information?.manager?.lastName || '',
                                    jobTitle: values.formData.information?.manager?.jobTitle || '',
                                    picture: values.formData.information.manager?.picture || '',
                                    cabinet: values.formData.information?.manager?.cabinet || '',
                                    id: values.formData.information?.manager?.id || ''
                                }
                            }] : undefined}
                            onSelectElement={onChangeManagerSelected}
                        />
                    </InputSection>
                </Row>
            </InnerWrapper>
            <Title>
                <MandatoryFieldStar />
                <FormattedMessage id="sponsoring.requiredInfo" />
            </Title>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.INFO}
            >
                <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'sponsoring.popup.step4.text6'})}}/>
            </Alert>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.DEFAULT}
            >
                <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'sponsoring.popup.step4.text7'})}}/>
            </Alert>
        </Wrapper>
    );
};
