import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useParams } from 'react-router-dom';

import { IState } from '../../reducers';
import { IContextReducer } from '../../reducers/contextReducer';
import { IMenuReducer } from '../../reducers/menuReducer';
import { setOrganizationsName, clearOrgsLastMembers } from '../../actions/organizationActions';
import { EOrganizationPermissionsBase } from '../../entities/IPermissions';
import { useOrganization } from '../../tools/organizationHooks';
import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { media } from '../../styleHelpers/breakpoint';
import { OrganizationMenu } from './Common/Menu/OrganizationMenu';
import { EMenuItem } from '../../entities/IMenu';
import { ETypeOfOrganization } from '../../entities/IOrganization';
import { ECampaignStatus, getSingleCampaign } from '../../actions/campaignActions';
import { getWorkspaceTranslations } from '../../actions/languageActions';
import { ESpinnerSize, Spinner } from '../Common/Spinner/Spinner';
import { MainTitle } from '../Common/MainTitle/MainTitle';
import { ILanguageReducer } from '../../reducers/languageReducer';
import { Layout } from '../Layout/Layout';

type SetOrganizationName = ReturnType<typeof setOrganizationsName>;
type ClearOrgsLastMembers = ReturnType<typeof clearOrgsLastMembers>;
type GetSingleCampaign = ReturnType<typeof getSingleCampaign>;
type GetWorkspaceTranslations = ReturnType<typeof getWorkspaceTranslations>;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 48px - 1.3rem);
    height: 100%;
    ${media.desktop`
        flex-direction: row;
    `}
`;

const NoPermissions = styled.div`
    color: ${colorStack.disabled};
    font-size: ${fontSize[16]};
    display: flex;
    justify-content: center;
    margin: 2rem 0;
`;

interface IContentProps {
    wide?: boolean;
}

const Content = styled.div<IContentProps>`
    width: 100%;
    height: auto;
    padding-left: 1.5rem;
    padding-right: 0.85rem;
    ${({ wide }) => css`
        ${media.desktop`
            :not(.print-layout &) {
                width: calc(100% - 280px);
            }
        `}
    `}
`;

const InnerContent = styled.div<{ $height?: boolean }>`
    margin: auto;
    height: 100%;
    ${props => props.$height && css`
        height: calc(100% - 80px);
    `}
    .loader-wrapper {
        height: 100%;
    }
`;

interface IOrganizationMainContainerProps {
    organizationUrlName?: string;
    withNormsConsultationToc?: boolean;
    bookCommonLevelId?: string;
}

export const OrganizationMainContainer: FC<React.PropsWithChildren<IOrganizationMainContainerProps>> = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { organization } = useParams<{ organization: string }>();
    const { id, campaignId } = useParams<{ id: string, campaignId: string }>();
    const { currentOrganization, currentOrgPermissions } = useOrganization(organization);
    const { isShadowAuth, menuItem, icon, withHeader, isInExportView, translations } = useSelector<IState, IContextReducer & IMenuReducer & ILanguageReducer>(state => ({
        ...state.context,
        ...state.menu,
        ...state.language
    }));
    const [additionalTitle, setAdditionalTitle] = useState<string>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!currentOrganization?.id) {
            return;
        }

        if (loading && Object.keys(translations)) {
            Promise.all([
                dispatch<SetOrganizationName>(setOrganizationsName(props.organizationUrlName)),
                dispatch<ClearOrgsLastMembers>(clearOrgsLastMembers()),
                dispatch<GetWorkspaceTranslations>(getWorkspaceTranslations(currentOrganization?.id))
            ]).then(() => {
                setLoading(false);
            });
        }
    }, [currentOrganization?.id, loading, translations]);

    useEffect(() => {
        if (menuItem === EMenuItem.campaignDashboard && campaignId && currentOrganization?.id) {
            dispatch<GetSingleCampaign>(getSingleCampaign(currentOrganization?.id, campaignId)).then(res => {
                if (res.campaign.status !== ECampaignStatus.Draft) {
                    setAdditionalTitle(res.campaign.name);
                } else {
                    setAdditionalTitle(undefined);
                }
            });
        }
    }, [currentOrganization?.id, campaignId]);

    return (
        <Layout>
            {loading ? (
                <Spinner size={ESpinnerSize.LARGE} />
            ) : (
                <>
                    {(currentOrgPermissions && currentOrganization) ? (
                        <Wrapper>
                            {currentOrgPermissions?.[EOrganizationPermissionsBase.ViewOrganization] ? (
                                <>
                                    {(!isShadowAuth && !isInExportView || (IS_TEAMS_INSTANCE && menuItem === EMenuItem.explore && !isInExportView) || (!IS_TEAMS_INSTANCE && !isShadowAuth && !isInExportView)) && <OrganizationMenu withNormsConsultationToc={props.withNormsConsultationToc} bookCommonLevelId={props?.bookCommonLevelId} />}
                                    <Content wide={props.withNormsConsultationToc}>
                                        {withHeader &&
                                            <MainTitle text={additionalTitle || intl.formatMessage({ id: `menu.l3.${menuItem}` })} icon={icon}/>
                                        }
                                        <InnerContent $height={!id && currentOrganization?.details.organizationType?.key !== ETypeOfOrganization?.NORMS && menuItem !== EMenuItem.explore}>
                                        <Outlet />
                                        </InnerContent>
                                    </Content>
                                </>
                            ) : (
                                <NoPermissions>
                                    <FormattedMessage id="global.noPermission" />
                                </NoPermissions>
                            )}
                        </Wrapper>
                    ) : (
                        <NoPermissions>
                            <FormattedMessage id="global.workspace.noPermission" />
                        </NoPermissions>
                    )}
                </>
            )}
        </Layout>
    );
};
