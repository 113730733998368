import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';

import { ESize } from '../../../styleHelpers/sizes';
import { media } from '../../../styleHelpers/breakpoint';
import { colorStack } from '../../../styleHelpers/colors';
import { DesktopNavMenu } from '../../NavMenu/DesktopNavMenu';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { ESizeLogo, Logo } from '../../Common/Logo/Logo';

const TopBarWrapper = styled.div`
    background: ${colorStack.white};
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 99998;
    height: ${ESize.TopBarHeightMobile}px;
    ${media.desktop`
        height: ${ESize.TopBarHeightDesktop}px;
    `}
`;

const Content = styled.div`
    height: ${ESize.TopBarHeightMobile}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${media.desktop`
        height: ${ESize.TopBarHeightDesktop}px;
    `}
    > button {
        display: none;
    }
`;

const LogoWrapper = styled(Link)`
    display: none;
    ${media.desktop`
        display: block;
        margin-left: 1.1rem;
        > img {
            height: 2rem;
        }
    `}
`;

const ClientLogo = styled.img`
    display: block;
    margin-right: 1rem;
    height: 2rem;
`;

const MobileMenu = styled(Button)`
    ${media.desktop`
        display: none;
    `}
`;

interface IProps {
    isLoggedIn: boolean;
    clientLogo: string;
    onMobileMenuClick();
}

export const TopBar: FC<React.PropsWithChildren<IProps>> = props => {

    return (
        <TopBarWrapper>
            <Content>
                <MobileMenu typeSchema={EButtonTypeSchema.TEXT} onClick={props.onMobileMenuClick}>
                    <FontAwesomeIcon icon={faBars} size="lg" />
                </MobileMenu>
                <LogoWrapper to="/">
                    <Logo size={ESizeLogo.SM} />
                </LogoWrapper>
                {props.isLoggedIn && (
                    <DesktopNavMenu />
                )}
                {props.clientLogo && (
                    <ClientLogo src={props.clientLogo} />
                )}
            </Content>
        </TopBarWrapper>
    );
};
