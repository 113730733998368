import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { rgba } from 'polished';

import { FileButton } from '../../Common/Buttons/Button';
import { colorStack } from '../../../styleHelpers/colors';
import { Attachment } from '../../Common/Attachment/Attachment';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';
import { IDirectoryContent } from '../../../entities/IClustersFilesystem';

const DropzoneWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
    align-items: center;
    > label {
        min-width: auto;
        overflow: visible;
    }
`;

const DropArea = styled.input`
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
`;

const CustomFileButton = styled(FileButton)`
    overflow: visible;
    cursor: pointer;
`;

const DottedArea = styled.div`
    border: 1px dashed ${colorStack.middleGrey};
    position: relative;
    flex-direction: column;
    border-radius: 4px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
        color: ${colorStack.middleBlue};
    }
`;

const FilesList = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const DropInner = styled.div`
    padding: 0.5rem 0;
    color: ${colorStack.middleBlue};
`;

const LoaderWrapper = styled.div`
    display: flex;
    position: absolute;
    flex-direction: row;
    z-index: 9;
    justify-content: center;
    background: ${rgba(colorStack.white, .8)};
    width: 100%;
    height: 100%;
    > div {
        margin: 0 0 0 .5rem;
    }
    &:first-child{
        padding-right: 1px;
    }
`;

interface IProps {
    loading: boolean;
    fileList: {
        directUri?: string;
        name: string;
        id?: string;
        fileId?: string;
    }[];
    clusterId?: string;
    canRemove?: boolean;
    hideCopyUrl?: boolean;
    hideView?: boolean;
    hideDownload?: boolean;
    id?: string;
    currentClusterName?: string;
    currentAttachmentData?: {id: string, name: string},
    entityId?: string;
    onChange(e: React.ChangeEvent<HTMLInputElement>);
    importFile?(file: IDirectoryContent, closeImportPopup: () => void, path: string[]);
    removeHandler?(id: string);
}

export const Dropzone: FC<React.PropsWithChildren<IProps>> = props => {

    const fileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e);
    }, [props.onChange]);

    const importFile = useCallback((file: IDirectoryContent, closeImportPopup: () => void, path: string[]) => {
        return new Promise((resolve) => props.importFile(file, closeImportPopup, path).then(() => {
            resolve(true);
        }));
    }, [props.onChange]);

    return (
        <DropzoneWrapper>
            <CustomFileButton
                multiple={false}
                onChange={fileInputChange}
                importFile={props.clusterId ? importFile : undefined}
            >
                <FormattedMessage id="global.browse" />
            </CustomFileButton>
            <DottedArea>
                {props.loading &&
                    <LoaderWrapper>
                        <Spinner size={ESpinnerSize.MEDIUM} />
                    </LoaderWrapper>
                }
                <DropInner>
                    <FormattedMessage id="global.orDropFileHere" />
                    <DropArea id="attachment" type="file" multiple={false} onChange={fileInputChange} />
                </DropInner>
                {(props.fileList?.length > 0 && (props.fileList?.[0]?.name || props?.currentAttachmentData)) &&
                    <FilesList>
                        {props.fileList?.map(file =>
                            <Attachment
                                key={props?.currentAttachmentData ? props?.currentAttachmentData?.id : file.id}
                                fileName={props?.currentAttachmentData ? props?.currentAttachmentData?.name : file?.name}
                                id={props?.currentAttachmentData ? props?.currentAttachmentData?.id : (props.entityId || !file?.id) ? file?.fileId : file?.id}
                                canRemove={props.canRemove}
                                clusterId={props.clusterId}
                                overviewPath
                                removeHandler={props.removeHandler}
                                entityId={props.entityId}
                                documentId={props.entityId ? file.id : undefined}
                            />
                        )}
                    </FilesList>
                }
            </DottedArea>
        </DropzoneWrapper>
    );
};