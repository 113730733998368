import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    font-size: ${fontSize[16]};
    line-height: 1;
    p {
        text-align: left;
        font-weight: 400;
        font-size: inherit;
        line-height: 1;
        color: ${colorStack.content};
        word-wrap: break-word;
    }
    ol, ul {
        padding-left: 3rem;
        font-size: inherit;
        line-height: 1;
    }
    ol {
        list-style: decimal;
        font-size: inherit;
        line-height: 1;
    }
    ul {
        list-style: disc;
        font-size: inherit;
        line-height: 1;
    }
    span {
        font-size: inherit;
        line-height: 1;
    }
`;

interface IProps {
    content: string;
}

export const NewRtView: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper dangerouslySetInnerHTML={{ __html: props.content }} />
    );
};