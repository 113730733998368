import { useContext, useEffect, useRef } from 'react';

import { sidebarTocContext } from '../../components/Soge/SidebarTocContext/SidebarTocContext';
import { ESize } from '../../styleHelpers/sizes';

export const useSidebarTocContext = () => {
    const context = useContext(sidebarTocContext);
    return context;
};

export const useNormsMenuTOCAutoScroll = <T extends HTMLElement>() => {
    const context = useSidebarTocContext();
    const treeScrollWrapper = useRef<T>();

    useEffect(() => {
        const id = context?.expandPath?.length && context.expandPath[context.expandPath.length - 1];
        const treeWrapper = treeScrollWrapper.current;
        const scrollToElement = id && document.querySelector(`[data-scrollto="${id}"]`) as HTMLElement;
        if (scrollToElement && treeWrapper) {
            treeWrapper.scrollTop = treeWrapper.scrollTop + scrollToElement.getBoundingClientRect().top - (ESize.TopBarHeightDesktop * 2);
        }
    }, [context?.expandPath, treeScrollWrapper.current]);

    return treeScrollWrapper;
};
