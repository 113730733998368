import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { EDeclarationTypeEnum } from '../../../../../../entities/IDeclaration';
import { ICOIData } from '../../../../../../entities/ActionData/ICOIData';
import { fontSize } from '../../../../../../styleHelpers/fontSizes';
import { ISIngleLegalEntity } from '../../../../../../entities/ILegalEntities';
import * as PopupStyles from '../../../../Common/Styles';
import { TextComponent } from '../../../../../Common/Inputs/TextComponent';
import { MandatoryFieldStar } from '../../../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { DatePickerWrapper } from '../../../../../Common/DatePicker/DatePicker';
import { LegalEntitiesPicker } from '../../../../../Common/Pickers/LegalEntitiesPicker';
import { NewRT } from '../../../../../Common/RichText/NewRT';
import { LabelWithEllipsis } from '../../../../../Common/Label/Label';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    position: relative;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div<{ alignEnd?: boolean }>`
    display: flex;
    flex-direction: row;
    ${props => props.alignEnd && css`
        align-items: end;
    `};
`;

const InputSection = styled.div<{ fullWidth?: boolean, fullHeight?: boolean }>`
    padding: 20px 28px;
    width: 50%;
    ${props => props.fullWidth && css`
        width: 100%;
    `}

    > p {
        margin-bottom: 20px;
        font-size: ${fontSize[20]};
        text-align: left;
    }
`;
const MarginWrapper = styled.div`
    margin-bottom: .5rem;
`;

const TranslationData = {
    [EDeclarationTypeEnum.CoiElectedPosition]: {
        firstTitle: 'coi.popup.electedPosition',
        firstLabel: 'coi.popup.position',
        secondTitle: 'coi.popup.jurisdiction.text',
        secondLabel: 'coi.popup.jurisdiction',
        thirdTitle: 'coi.popup.duration',
        thirdLabel: 'coi.popup.startElectedDate',
        fourthLabel: 'coi.popup.endElectedDate',
        fifthLabel: 'coi.popup.electedContext',
        fourthTitle: 'coi.popup.electedOpinion'
    },
    [EDeclarationTypeEnum.CoiFamilyInterest]: {
        firstTitle: 'coi.popup.personAndFunction.title',
        firstLabel: 'coi.popup.personAndFunction.label',
        secondTitle: 'coi.popup.companyOrOrganization.title',
        secondLabel: 'coi.popup.companyOrOrganization.label',
        thirdTitle: 'coi.popup.situationDuration',
        thirdLabel: 'coi.popup.startFamilyDate',
        fourthLabel: 'coi.popup.endFamilyDate',
        fifthLabel: 'coi.popup.familyContext',
        fourthTitle: 'coi.popup.familyOpinion'
    },
    [EDeclarationTypeEnum.CoiFinance]: {
        firstTitle: 'coi.popup.contoursOfParticipation.title',
        firstLabel: 'coi.popup.description',
        secondTitle: 'coi.popup.companyOrOrganization.FinanceTitle',
        secondLabel: 'coi.popup.companyOrOrganization.FinanceLabel',
        thirdTitle: 'coi.popup.FinanceDuration',
        thirdLabel: 'coi.popup.startFinanceDate',
        fourthLabel: 'coi.popup.endFinanceDate',
        fifthLabel: 'coi.popup.financeContext',
        fourthTitle: 'coi.popup.financeOpinion'
    },
    [EDeclarationTypeEnum.CoiAssociationMandate]: {
        firstTitle: 'coi.popup.whatPosition.title',
        firstLabel: 'coi.popup.mandatePosition',
        secondTitle: 'coi.popup.association.title',
        secondLabel: 'coi.popup.association.label',
        thirdTitle: 'coi.popup.mandateSituationDuration',
        thirdLabel: 'coi.popup.startMandateDate',
        fourthLabel: 'coi.popup.endMandateDate',
        fifthLabel: 'coi.popup.mandateContext',
        fourthTitle: 'coi.popup.mandateOpinion'
    },
    [EDeclarationTypeEnum.CoiOtherLink]: {
        firstTitle: 'coi.popup.describeSituation.title',
        firstLabel: 'coi.popup.otherDescription',
        secondTitle: 'coi.popup.companyOrOrganizationInvolved.title',
        secondLabel: 'coi.popup.companyOrOrganization.Otherlabel',
        thirdTitle: 'coi.popup.otherSituationDuration',
        thirdLabel: 'coi.popup.startOtherDate',
        fourthLabel: 'coi.popup.endOtherDate',
        fifthLabel: 'coi.popup.otherContext',
        fourthTitle: 'coi.popup.otherOpinion'
    }
};

interface IProps {
    declarationType: string;
}

export const LinkOfInterest: FC<React.PropsWithChildren<IProps>> = props => {
    const { setFieldValue, values } = useFormikContext<ICOIData>();

    const fillLegalEntityInformation = useCallback((data: ISIngleLegalEntity) => {
        setFieldValue(`formData.coiDeclaration.thirdParty`, data ? {
            name: data?.name,
            picture: data?.picture,
            id: data?.id,
            type: data.type
        } : undefined);
    }, []);

    const onChangeSelectedEntity = useCallback((element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        fillLegalEntityInformation(element?.[0]?.data);
    }, []);

    const changeStartDate = useCallback((target: string, endAt: Date) => {
        const changeHour = endAt ? new Date(new Date(endAt).setHours(7)) : undefined;
        setFieldValue(target, changeHour);
    }, []);

    return (
        <>
            <Wrapper>
                <InnerWrapper>
                    <Row>
                        <InputSection fullWidth>
                            <MarginWrapper>
                                <FormattedMessage id={TranslationData[props.declarationType]?.firstTitle || 'coi.popup.electedPosition'} />
                            </MarginWrapper>
                            <LabelWithEllipsis required>
                                <FormattedMessage id={TranslationData[props.declarationType]?.firstLabel || 'coi.popup.position'} />
                            </LabelWithEllipsis>
                            <TextComponent
                                value={values.formData.coiDeclaration.description}
                                onChange={(value: string) => setFieldValue('formData.coiDeclaration.description', value)}
                            />
                        </InputSection>
                    </Row>
                    <Row>
                        <InputSection fullWidth>
                            <MarginWrapper>
                                <FormattedMessage id={TranslationData[props.declarationType]?.secondTitle || 'coi.popup.jurisdiction.text'} />
                            </MarginWrapper>
                            <LabelWithEllipsis required>
                                <FormattedMessage id={TranslationData[props.declarationType]?.secondLabel || 'coi.popup.jurisdiction.text'} />
                            </LabelWithEllipsis>
                            <LegalEntitiesPicker
                                value={values.formData?.coiDeclaration?.thirdParty ? [{
                                    key: values.formData?.coiDeclaration?.thirdParty?.id,
                                    text: values.formData?.coiDeclaration?.thirdParty?.name || values.formData.coiDeclaration.thirdParty?.name,
                                    data: values.formData?.coiDeclaration?.thirdParty
                                }] : undefined}
                                onSelectElement={onChangeSelectedEntity}
                                allCompanies
                            />
                        </InputSection>
                    </Row>
                    <Row alignEnd>
                        <InputSection>
                            <MarginWrapper>
                                <FormattedMessage id={TranslationData[props.declarationType]?.thirdTitle || 'coi.popup.duration'} />
                            </MarginWrapper>
                            <LabelWithEllipsis required>
                                <FormattedMessage id={TranslationData[props.declarationType]?.thirdLabel || 'coi.popup.duration'} />
                            </LabelWithEllipsis>
                            <DatePickerWrapper
                                value={values.formData.coiDeclaration.startingDate}
                                onChange={(val) => changeStartDate('formData.coiDeclaration.startingDate', val)}
                            />
                        </InputSection>
                        <InputSection>
                            <LabelWithEllipsis>
                                <FormattedMessage id={TranslationData[props.declarationType]?.fourthLabel || 'coi.popup.endElectedDate'} />
                            </LabelWithEllipsis>
                            <DatePickerWrapper
                                value={values.formData.coiDeclaration.endingDate}
                                onChange={(val) => changeStartDate('formData.coiDeclaration.endingDate', val)}
                            />
                        </InputSection>
                    </Row>
                    <Row>
                        <InputSection fullHeight fullWidth>
                            <MarginWrapper>
                                <FormattedMessage id={TranslationData[props.declarationType]?.fourthTitle || 'coi.popup.electedOpinion'} />
                            </MarginWrapper>
                            <LabelWithEllipsis><FormattedMessage id={TranslationData[props.declarationType]?.fifthLabel || 'coi.popup.electedContext'} /></LabelWithEllipsis>
                            <NewRT
                                content={values.formData.coiDeclaration.context}
                                onChange={(text) => setFieldValue('formData.coiDeclaration.context', text)}
                            />
                        </InputSection>
                    </Row>
                </InnerWrapper>
                <PopupStyles.RequiredInformation>
                    <PopupStyles.Title>
                        <MandatoryFieldStar />
                        <FormattedMessage id="coi.popup.requiredInfo" />
                    </PopupStyles.Title>
                </PopupStyles.RequiredInformation>
            </Wrapper>
        </>
    );

};