import { Dispatch } from 'redux';

import { action, IDispatch } from '../tools/reduxTools';
import { EReferentials, ISearchResponse, IUserBrief, IReferencial, EContextList } from '../entities/IGlobal';
import { IReferentialsIdentities } from '../entities/IClusters';
import { EProfileDataType } from '../tools/profileConstants';
import { ISearchReferentialElements } from '../entities/IProfile';
import { IGlobalTypes, SET_REFERENTIAL } from './actionTypes/globalTypes';
import { IState } from '../reducers';
import { Http } from '../tools/http';
import { ELcid } from '../entities/ILanguage';

export const getReferentialsChildren = (query: string = '', referentialIdentity: string, parent?: string) => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/referentials/elements/${referentialIdentity}/children?size=9999&lcid=${userLanguage}${query.length > 0 ? `&query=${query}` : ''}${parent ? `&parent=${parent}` : ''}`);
});

export const getReferentials = (query: string = '', type: EProfileDataType | EReferentials, context: EContextList = EContextList.Data, parent?: string, bustCache?: boolean) => action<Promise<IReferencial[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const { referentials } = getState().global;
    let obj = referentials || {};
    let isExistContext = !!obj?.[context];
    let isExistType = !!obj?.[context]?.[type];
    if (!isExistContext || !isExistType || parent || bustCache) {
        return http.profilesApiGet(`/referentials/contexts/${context}/types/${type}/elements?size=9999&lcid=${userLanguage}${query.length > 0 ? `&query=${query}` : ''}${parent ? `&parent=${parent}` : ''}`).then((response: ISearchReferentialElements) => {
            if (isExistContext) {
                if (!isExistType) {
                    obj = { ...obj, [context]: { ...obj[context], [type]: response.items } };
                }
            } else {
                obj = { ...obj, [context]: { [type]: response.items } };
            }
            dispatch<IDispatch<IGlobalTypes['SET_REFERENTIAL']>>({
                type: SET_REFERENTIAL,
                referentialsList: obj
            });
            return response.items;
        });
    }
    return new Promise((resolve) => {
        if (referentials?.[context]?.[type]) {
            resolve(referentials?.[context]?.[type]?.filter(elem => elem?.name?.toLowerCase()?.includes(query?.toLowerCase())));
        } else {
            resolve([]);
        }
    });
});

export const getUnits = () => action<Promise<ISearchReferentialElements>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiGet(`/referentials/units?size=9999&lcid=${userLanguage}`);
});

export const getReferentialsIdentities = (query: string = '') => action<Promise<IReferentialsIdentities[]>>((dispatch, getState, http) => {
    return http.profilesApiGet(`/referentials/identities?size=9999${query.length > 0 ? `&query=${query}` : ''}`).then((res: ISearchResponse<IReferentialsIdentities>) => res.items);
});

export const getAllClusterReferentials = (clusterTemplateId: string) => action<Promise<{ identityContext: EContextList; identityId: string; identityType: EProfileDataType | EReferentials; }[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const { referentials } = getState().global;
    return http.clustersApiGet(`/cluster-templates/${clusterTemplateId}/referentials?lcid=${userLanguage}`).then(response => {
        let obj = referentials || {};
        for (let i = 0; i < response.length; i++) {
            let isExistContext = !!obj?.[response[i].identityContext];
            if (isExistContext) {
                let isExistType = !!obj?.[response[i].identityContext]?.[response[i].identityType];
                if (!isExistType) {
                    obj = { ...obj, [response[i].identityContext]: { ...obj[response[i].identityContext], [response[i].identityType]: response[i].elements } };
                }
            } else {
                obj = { ...obj, [response[i].identityContext]: { [response[i].identityType]: response[i].elements } };
            }

        }
        dispatch<IDispatch<IGlobalTypes['SET_REFERENTIAL']>>({
            type: SET_REFERENTIAL,
            referentialsList: obj
        });
    });
});

export const getMultipleUsersById = (body: string[]) => action<Promise<IUserBrief[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    return http.profilesApiPost(`/users/by-ids?lcid=${userLanguage}`, body);
});

export const getReferentialsByUniqueKey = (uniqueKey: string) => action<Promise<{name: {label: string, lcid: ELcid}[]}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { referentials } = getState().global;
    let obj = referentials || {};
    let isExistByUniqueKey = !!obj?.[uniqueKey];
    if (!isExistByUniqueKey) {
        return http.profilesApiGet(`/referentials/elements/${uniqueKey}`).then(res => {
            obj = { ...obj, [uniqueKey]: res };
            dispatch<IDispatch<IGlobalTypes['SET_REFERENTIAL']>>({
                type: SET_REFERENTIAL,
                referentialsList: obj
            });
            return res;
        });
    }
    return new Promise((resolve) => {
        if (referentials?.[uniqueKey]) {
            resolve(referentials?.[uniqueKey]);
        } else {
            resolve([]);
        }
    });
});
