import React, { useState, DragEvent, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faTrash, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition, faFileImage, faFilePdf, faFileWord, faFileExcel, faSquareQuestion, faXmark, faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { TextWithEllipsis } from '../TextWithEllipsis/TextWithEllipsis';
import { LabelWithEllipsis } from '../Label/Label';
import { ContextMenu, ISingleLink } from '../ContextMenu/ContextMenu';
import { uuidv4 } from '../../../tools/authTools';
import { useDropdown } from '../../../tools/hooks';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';

const computeHumanFriendlyFileSize = (fileSize: number) => {
    if (fileSize >= 10e8) {
        return `${(fileSize * 10e-10).toFixed(2)}GB`;
    }

    if (fileSize >= 10e5) {
        return `${(fileSize * 10e-7).toFixed(2)}MB`;
    }

    return `${Math.ceil(fileSize * 10e-4)}KB`;
};

const GlobalWrapper = styled.div<{ withLabel: boolean }>`
    display: flex;
    align-items: center;

    ${({ withLabel }) => withLabel && css`
        margin-top: 4px;
    `}
`;

const Wrapper = styled.div<{ vertical: boolean, inProgress?: boolean, complete?: boolean }>`
    display: flex;
    ${({ vertical, complete, inProgress }) => vertical && !complete && !inProgress && css`
        flex-direction: column;
        height: 242px;
        justify-content: center;
        align-items: center;
        text-align: center;
    `}
    ${({ inProgress, complete }) => !complete && !inProgress && css`
        align-items: center;
        min-width: 311px;
        padding: 16px;
        gap: 16px;
        border: 1px dashed ${colorStack.ligthGrey};
        border-radius: 8px;
    `}
`;

const MenuWrapper = styled.div<{}>`
    position: relative;
`;

const ThreeDotsMenu = styled.div<{}>`
    display: flex;
    ${fontSizeAndHeight[25]}
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
        color: ${colorStack.darkGrey};
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${colorStack.darkGrey};
`;

const Action = styled.div`
    cursor: pointer;
`;

const FileInfo = styled.div<{ withSizeOrDate: boolean, withProgress: boolean, complete: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    box-sizing: content-box;

    padding: 4px 8px 4px 4px;

    ${({ withSizeOrDate, withProgress }) => (withSizeOrDate || withProgress) && css`
        gap: 16px;
        padding: 16px;
    `}

    ${({ complete }) => complete && css`
        padding-right: 16px;
    `}

    &:hover {
        background-color: ${colorStack.whiteBlue};
    }

    &:focus {
        outline: 1.5px solid ${colorStack.ligthGrey};
    }
`;

const FileNameSizeAndDate = styled.div<{}>`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const FileName = styled(TextWithEllipsis) <{}>`
    font-weight: 500;
    ${fontSizeAndHeight[13]}
    color: ${colorStack.content};
`;

const FileSizeAndDate = styled.div<{}>`
    color: ${colorStack.darkGrey};
    ${fontSizeAndHeight[10]}
`;

const IconWrapper = styled.div<{ color: string, withSizeOrDate: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ withSizeOrDate }) => withSizeOrDate && css`
        width: 32px;
        height: 32px;
        border-radius: 8px;
    `}

    ${({ color }) => css`
        background-color: ${color};
        svg {
            ${fontSizeAndHeight[16]}
            color: ${colorStack.white};
        }
    `}
`;

const CloudAndTextWrapper = styled.div<{ vertical: boolean }>`
    ${fontSizeAndHeight[31]};
    display: inline-flex;
    align-items: center;
    height: 32px;
    gap: 12px;

    ${({ vertical }) => vertical && css`
        ${fontSizeAndHeight[39]}
        margin-bottom: 8px;
    `};
`;

const TextWrapper = styled.div`
    display: block;
    display: flex;
    flex-direction: column;
    gap: 2px;
    > span {
        height: 16px;
    }
`;

const Text = styled.span<{}>`
    display: inline-flex;
    ${fontSizeAndHeight[13]}
    font-weight: 500;
`;

const HiddenBrowseButton = styled.input<{}>`
    display: none !important;
`;

const BrowseButtonLabel = styled.label<{}>`
    ${fontSizeAndHeight[16]}
    color: ${colorStack.middleBlue};
    cursor: pointer;
`;

const SubText = styled.span<{}>`
    display: block;
    ${fontSizeAndHeight[10]}
    font-weight: 400;
    color: ${colorStack.darkGrey};
`;

const UploadProgressWrapper = styled.div<{ progressPercentage: number }>`
    display: flex;
    flex-direction: column;
    width: 237px;
    color: ${colorStack.darkGrey};
    ${fontSizeAndHeight[10]}
`;

const UploadProgressSegment = styled.div<{ progressPercentage: number }>`
    position: relative;
    height: 8px;
    width: 100%;
    background-color: ${colorStack.ligthGrey};
    border-radius: 8px;
    margin: 8px 0;

    &:before {
        position: absolute;
        content: '';
        height: 8px;
        border-radius: 8px;
        background-color: ${colorStack.blue};
        ${({ progressPercentage }) => css`
            width: calc(${progressPercentage}%);
        `}
    }
`;

const UploadErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const ErrorText = styled.span`
    ${fontSizeAndHeight[10]}
    color: ${colorStack.middleRed};
`;

const DownloadIconWrapper = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DownloadIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.99992 9.33203C2.36811 9.33203 2.66659 9.63051 2.66659 9.9987V12.6654C2.66659 12.8422 2.73682 13.0117 2.86185 13.1368C2.98687 13.2618 3.15644 13.332 3.33325 13.332H12.6666C12.8434 13.332 13.013 13.2618 13.138 13.1368C13.263 13.0117 13.3333 12.8422 13.3333 12.6654V9.9987C13.3333 9.63051 13.6317 9.33203 13.9999 9.33203C14.3681 9.33203 14.6666 9.63051 14.6666 9.9987V12.6654C14.6666 13.1958 14.4559 13.7045 14.0808 14.0796C13.7057 14.4546 13.197 14.6654 12.6666 14.6654H3.33325C2.80282 14.6654 2.29411 14.4547 1.91904 14.0796C1.54397 13.7045 1.33325 13.1958 1.33325 12.6654V9.9987C1.33325 9.63051 1.63173 9.33203 1.99992 9.33203Z" fill="#616161" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.19526 6.19526C4.45561 5.93491 4.87772 5.93491 5.13807 6.19526L8 9.05719L10.8619 6.19526C11.1223 5.93491 11.5444 5.93491 11.8047 6.19526C12.0651 6.45561 12.0651 6.87772 11.8047 7.13807L8.4714 10.4714C8.21106 10.7318 7.78894 10.7318 7.5286 10.4714L4.19526 7.13807C3.93491 6.87772 3.93491 6.45561 4.19526 6.19526Z" fill="#616161" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.99992 1.33203C8.36811 1.33203 8.66659 1.63051 8.66659 1.9987V9.9987C8.66659 10.3669 8.36811 10.6654 7.99992 10.6654C7.63173 10.6654 7.33325 10.3669 7.33325 9.9987V1.9987C7.33325 1.63051 7.63173 1.33203 7.99992 1.33203Z" fill="#616161" />
</svg>;

export enum EUploaderFileFormat {
    DOCX = 'DOCX',
    JPEG = 'JPEG',
    JPG = 'JPG',
    PDF = 'PDF',
    PNG = 'PNG',
    XLS = 'XLS',
    XLSX = 'XLSX'
}

export interface IFileUploaderChangeEvent {
    rawFile: File;
    fileName: string;
    date: Date;
    fileId: string;
    mimeType: string;
    fileSizeInBytes: number;
    humanFriendlyFileSize: string;
}

interface IUploaderProps {
    label?: string;
    fileId?: string;
    fileFormats: EUploaderFileFormat[];
    fileName?: string;
    fileSizeInBytes?: number;
    fileDate?: Date;
    required?: boolean;
    vertical?: boolean;
    inProgress?: boolean;
    progressPercentage?: number;
    error?: string;
    threeDotsMenuActions?: ISingleLink[];
    onFileChange?(e: IFileUploaderChangeEvent): void;
    downloadAction?(e): void;
    removeAction?(id: string): void;
    cancelAction?(): void;
}

export const Uploader = (props: IUploaderProps) => {
    const [id, _] = useState<string>(uuidv4());
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();
    const [complete, setComplete] = useState<boolean>(!!props.fileId);
    const [fileName, setFileName] = useState<string>(props.fileName);
    const [fileSize, setFileSize] = useState<number>(props.fileSizeInBytes);
    const [fileDate, setFileDate] = useState<Date>(props.fileDate);
    // tslint:disable-next-line:no-null-keyword
    const [file, setFile] = useState<File>(null);

    useEffect(() => {
        setFileName(props.fileName);
    }, [props.fileName]);

    useEffect(() => {
        setComplete(!!props.fileId);
    }, [props.fileId]);

    useEffect(() => {
        setFileSize(props.fileSizeInBytes);
    }, [props.fileSizeInBytes]);

    const processFile = (fileObject: File) => {
        const fileObjectSize = fileObject.size;
        const fileObjectName = fileObject.name;
        const mimeType = fileObject.type;
        const date = new Date(fileObject.lastModified);
        const fileId = uuidv4();
        // remember the file as well for retrying in case it fails
        setFile(fileObject);
        setFileName(fileObjectName);
        setFileSize(fileObjectSize);
        setFileDate(date);
        setComplete(true);
        props.onFileChange?.({
            rawFile: fileObject,
            fileSizeInBytes: fileObjectSize,
            date,
            fileName: fileObjectName,
            mimeType,
            fileId,
            humanFriendlyFileSize: computeHumanFriendlyFileSize(fileObjectSize)
        });
    };

    const fileFormat: EUploaderFileFormat | null = useMemo(() => {
        if (fileName) {
            const format = fileName.split('.').pop();
            switch (format?.toLowerCase()) {
                case 'docx':
                    return EUploaderFileFormat.DOCX;
                case 'jpg':
                    return EUploaderFileFormat.JPG;
                case 'jpeg':
                    return EUploaderFileFormat.JPEG;
                case 'pdf':
                    return EUploaderFileFormat.PDF;
                case 'png':
                    return EUploaderFileFormat.PNG;
                case 'xls':
                    return EUploaderFileFormat.XLS;
                case 'xlsx':
                    return EUploaderFileFormat.XLSX;
                default: return EUploaderFileFormat.DOCX;
            }
        }

        // tslint:disable-next-line:no-null-keyword
        return null;
    }, [fileName]);

    const relevantFileIcon: IconDefinition = useMemo(() => {
        if (fileFormat === null) {
            return faSquareQuestion;
        }

        switch (fileFormat) {
            case EUploaderFileFormat.DOCX:
                return faFileWord;
            case EUploaderFileFormat.JPEG:
            case EUploaderFileFormat.JPG:
            case EUploaderFileFormat.PNG:
                return faFileImage;
            case EUploaderFileFormat.PDF:
                return faFilePdf;
            case EUploaderFileFormat.XLS:
            case EUploaderFileFormat.XLSX:
                return faFileExcel;
            default: return faFileWord;
        }
    }, [fileFormat]);

    const onDragEnter = (e: DragEvent<HTMLElement>) => {
        if (complete) {
            return;
        }
    };

    const onDragLeave = (e: DragEvent<HTMLElement>) => {
        if (complete) {
            return;
        }
    };

    const onDrop = (e: DragEvent<HTMLElement>) => {
        e.preventDefault();

        if (complete) {
            return;
        }

        const fileObject = e.dataTransfer.files[0];
        fileObject && processFile(fileObject);
    };

    const onDragOver = (e: DragEvent<HTMLElement>) => {
        e.preventDefault();

        if (complete) {
            return;
        }
    };

    const onDialogUploadChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileObjectName = e.target.value;

        if (fileObjectName) {
            const fileObject = e.target.files[0];
            processFile(fileObject);
        }
    };

    const humanFriendlyFileSize: string = useMemo(() => {
        return computeHumanFriendlyFileSize(fileSize);
    }, [fileSize]);

    const acceptableFileExtensionsAttr: string = useMemo(() => {
        return props.fileFormats.map(format => `.${format.toLowerCase()}`).join(',');
    }, [props.fileFormats]);

    const iconWrapperColor: string = useMemo(() => {
        switch (relevantFileIcon) {
            case faFileExcel:
                return colorStack.green;

            case faFilePdf:
                return colorStack.red;

            case faFileImage:
                return colorStack.orange;

            case faFileWord:
                return colorStack.blue;

            case faSquareQuestion:
                return colorStack.disabled;

            default:
                return colorStack.content;
        }
    }, [relevantFileIcon]);

    return (
        <>
            {props.label && (
                <LabelWithEllipsis>
                    {props.label}
                    {props.required && <MandatoryFieldStar />}
                </LabelWithEllipsis>
            )}
            <GlobalWrapper withLabel={!!props.label}>
                <Wrapper
                    vertical={props.vertical}
                    complete={complete}
                    inProgress={props.inProgress}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    onDragOver={onDragOver}>
                    {!fileName &&
                        <>
                            <CloudAndTextWrapper vertical={props.vertical}>
                                <FontAwesomeIcon icon={faCloud} />
                            </CloudAndTextWrapper>
                            <TextWrapper>
                                <form>
                                    <Text><FormattedMessage id="upload.dragAndDropFilesOr" />&nbsp;
                                        <HiddenBrowseButton id={id} type="file" accept={acceptableFileExtensionsAttr} onChange={onDialogUploadChoice} />
                                        <BrowseButtonLabel htmlFor={id}><FormattedMessage id="upload.browse" /></BrowseButtonLabel>
                                    </Text>
                                </form>
                                <SubText><FormattedMessage id="upload.supportedFormats" />&nbsp;{props.fileFormats.join(', ')}</SubText>
                            </TextWrapper>
                            {!fileName && props.threeDotsMenuActions?.length > 0 &&
                                <MenuWrapper ref={wrapperRef}>
                                    <ThreeDotsMenu onClick={toggleDropdown}><FontAwesomeIcon icon={faEllipsis} scale={1.5} /></ThreeDotsMenu>
                                    {dropdownOpen && <ContextMenu links={props.threeDotsMenuActions.map(link => ({
                                        ...link,
                                        action: () => { toggleDropdown(); link.action(); }
                                    }))} />}
                                </MenuWrapper>
                            }
                        </>
                    }
                    {fileName &&
                        <FileInfo tabIndex={-1} withSizeOrDate={fileSize !== undefined || !!fileDate} withProgress={props.inProgress} complete={complete}>
                            <IconWrapper color={iconWrapperColor} withSizeOrDate={fileSize !== undefined || !fileDate}>
                                <FontAwesomeIcon icon={relevantFileIcon} />
                            </IconWrapper>
                            {props.error &&
                                <UploadErrorWrapper>
                                    <FileName>{fileName}</FileName>
                                    <ErrorText>{props.error}</ErrorText>
                                </UploadErrorWrapper>
                            }
                            {!props.error && (props.inProgress ?
                                <UploadProgressWrapper progressPercentage={props.progressPercentage}>
                                    <FileName>{fileName}</FileName>
                                    <UploadProgressSegment progressPercentage={props.progressPercentage} />
                                    <FormattedMessage id="global.percentComplete" values={{ percentage: props.progressPercentage }} />
                                </UploadProgressWrapper>
                                : <FileNameSizeAndDate>
                                    <FileName>{fileName}</FileName>
                                    {(fileSize || fileDate) &&
                                        <FileSizeAndDate>
                                            {humanFriendlyFileSize} {fileDate && '.'} {moment.utc(fileDate).local().format('DD MMM, YYYY')}
                                        </FileSizeAndDate>
                                    }
                                </FileNameSizeAndDate>
                            )}
                            <ActionsWrapper>
                                {!props.error && props.inProgress && props.cancelAction &&
                                    <Action onClick={() => props.cancelAction()}><FontAwesomeIcon icon={faXmark} /></Action>
                                }
                                {!props.error && complete && props.downloadAction &&
                                    <Action onClick={(e) => props.downloadAction(e)}><DownloadIconWrapper>{DownloadIcon}</DownloadIconWrapper></Action>
                                }
                                {!props.error && complete && props.removeAction &&
                                    <Action onClick={() => props.removeAction(props.fileId)}><FontAwesomeIcon icon={faTrash} /></Action>
                                }
                                {props.error && file &&
                                    <Action onClick={() => processFile(file)}><FontAwesomeIcon icon={faRotateRight} /></Action>
                                }
                            </ActionsWrapper>
                        </FileInfo>
                    }
                </Wrapper>
            </GlobalWrapper>
        </>
    );
};
