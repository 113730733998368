import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
`;
export const Confirm: FC = props => {
    return (
        <Wrapper>
            You sucesfully send a request.
        </Wrapper>
    );
};