import { Dispatch } from 'redux';

import * as actionTypes from './actionTypes/contextTypes';
import * as organizationActionTypes from './actionTypes/organizationsTypes';
import { action, IDispatch } from '../tools/reduxTools';
import { Http } from '../tools/http';
import { IOrganizationContext } from '../entities/IContext';
import { ISendEmailResponse } from '../components/ITeams';

export const tokenReload = (loading: boolean) => ({
    type: actionTypes.SET_TOKEN_RELOAD_LOADER,
    loading
});

export const setLoggedInUser = (
    accessToken: string,
    oid: string,
    tokenExpiration: number,
    user: {
        email: string,
        displayName: string,
        department?: string,
        userOrigin?: string
    }
) => ({
    type: actionTypes.SET_LOGGED_IN_USER,
    user,
    accessToken,
    tokenExpiration,
    oid
});

export const clearUserContext = () => ({
    type: actionTypes.CLEAR_USER_CONTEXT
});

export const setPasswordResetMode = (passwordResetMode: boolean = true) => ({
    type: actionTypes.SET_PASSWORD_RESET_MODE,
    passwordResetMode
});

export const setGPlacesLoaded = () => ({
    type: actionTypes.LOAD_EXTERNAL_GPLACES
});

export const requestTokenRenewal = () => ({
    type: actionTypes.REQUEST_TOKEN_RENEWAL
});

export const getCurrentUserContextOrganizations = () => action<Promise<IOrganizationContext[]>>((dispatch: Dispatch, getState, http: Http) => {
    const { userLanguage } = getState().language;

    return http.organizationsApiGet(`/Organizations/my?size=99999&lcid=${userLanguage}`)
        .then((response) => {
            const organizations = response.items || [];
            dispatch({
                type: actionTypes.LOAD_ORGANIZATION_CONTEXTS,
                organizations
            });
            dispatch<IDispatch<organizationActionTypes.IOrganizationsTypes['SET_ORGANIZATION_PERMISSIONS']>>({
                type: organizationActionTypes.SET_ORGANIZATION_PERMISSIONS,
                list: organizations.map((organization) => ({
                    id: organization.id,
                    permissions: organization.permissions
                }))
            });
            return organizations;
        });
}
);

export const addTagsToContextOrganization = (
    organizationUrl: string,
    tags: string[]
) => ({
    type: actionTypes.ADD_TAGS_TO_CONTEXT_ORGANIZATION,
    organizationUrl,
    tags
});

export const setAccessToken = (token: string, isShadowAuth = false) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    token,
    isShadowAuth
});

export const setExportView = (isInExportView: boolean) => ({
    type: actionTypes.SET_EXPORT_VIEW,
    isInExportView
});

export const getEnvironmentUrl = (email: string) => action<Promise<ISendEmailResponse>>(
    (dispatch, getState, http) => {
        return http.apiGet(`/get-base-configuration?email=${email}`);
    }
);
