import React, { useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChunkLoader } from '../Common/ChunkLoader';

export const NullPage: FC = () => {
    const history = useNavigate();
    useEffect(() => {
        history('/');
    }, []);

    return (
        <ChunkLoader />
    );
};
