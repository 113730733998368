import React, { FC } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { getTimeFromNow } from '../../../../tools/date';

export const DateAgoType: FC<React.PropsWithChildren<unknown>> = props => {
    const intl = useIntl();
    return (
        <span>{intl?.formatMessage({ id: 'global.dateAgo' }, { date: getTimeFromNow(props.children as string) })}</span>
    );
};