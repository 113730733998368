import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { GlobalLoader } from '../GlobalLoader';
import { getHashParameters, AuthState, EAuthConstants, EMsalErrorCode } from '../../tools/authTools';
import { localStorage, getWithExpiry } from '../../tools/storage';
import { setLoggedInUser } from '../../actions/contextActions';

const Wrapper = styled.div`
    padding: 1rem;
`;

export const AuthEnd: FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [errorMsg, setErrorMsg] = useState<string>(undefined);

    const authSuccessCallback = (accessToken: string, idToken: string, nextRequestUrl: string) => {
        localStorage.setItem(EAuthConstants.IDKey, idToken);

        const tokenData = jwtDecode(idToken);
        dispatch(setLoggedInUser(accessToken, tokenData.oid, tokenData.exp, {
            email: tokenData?.email,
            displayName: tokenData?.name,
            department: tokenData?.department,
            userOrigin: tokenData?.user_origin
        }));
        history(decodeURIComponent(nextRequestUrl || '') || '/');
    };

    useEffect(() => {
        const response = getHashParameters(window.location.hash);
        if (response.error) {
            const errorDesc = response.error_description;

            if (errorDesc.match(EMsalErrorCode.PasswordResetRequested)) {
                history('/reset-password');
            } else if (errorDesc.match(EMsalErrorCode.PasswordResetCancelled)) {
                history('/login');
            } else {
                setErrorMsg(errorDesc);
            }
        } else if (response.access_token) {
            const expectedStateCsrf = getWithExpiry(EAuthConstants.CSRFState, true);

            try {
                const state: AuthState = JSON.parse(atob(response.state));

                if (expectedStateCsrf !== state.csrf) {
                    setErrorMsg('We seem to have a minor issue. This often happens when opening too many tabs at the same time.  Going back and clicking again on the links should fix it.');
                } else {
                    if (response.id_token && response.access_token) {
                        authSuccessCallback(response.access_token, response.id_token, state.nextRequestUrl);
                    } else {
                        setErrorMsg('No id_token or access_token');
                    }
                }
            } catch (e) {
                setErrorMsg(`Unable to parse state: ${e}`);
            }
        } else {
            setErrorMsg('Unexpected failure');
        }
    }, []);

    return errorMsg ? (
        <Wrapper>
            <p>{errorMsg}</p>
        </Wrapper>
    ) : (
        <GlobalLoader isLoading />
    );
};